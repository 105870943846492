export const styles = {
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0, 0.1)",
    zIndex: 9,
    position: "absolute" as const,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  choseLocationModal: {
    width: "548px",
    height: "337px",
    marginTop: "-150px",
    padding: "32px 24px 24px 24px",
    borderRadius: "8px",
    background: "white",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-between",
  },
  title: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "29.23px",
  },
  text: {
    fontSize: "16px",
  },
  label: {
    marginTop: "15px",
    marginBottom: "5px",
  },
  mandatory: {
    color: "#D72B4F",
  },
  button: {
    position: "relative" as const,
    cursor: "pointer",
    fontWeight: 700,
    marginTop: "15px",
    border: "1px solid #E2E2FC",
    borderRadius: "4px",
    padding: "10px",
  },
  disabled: {
    cursor: "default",
    color: "#E2E2FC",
  },
  submittingIcon: {
    position: "absolute" as const,
    top: "11px",
    right: "37%",
    color: "black",
  },
};

import React from "react";

export const SmallFlyerIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="60" height="28" viewBox="0 0 60 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_d_3739_82034)">
        <path
          d="M4.52734 4.64844C4.52734 3.54387 5.42277 2.64844 6.52734 2.64844L48.1495 2.64844C48.6802 2.64844 49.1891 2.85934 49.5642 3.23471L54.9745 8.64873C55.3493 9.02377 55.5598 9.53227 55.5598 10.0625V19.3681C55.5598 20.4726 54.6644 21.3681 53.5598 21.3681H6.52734C5.42277 21.3681 4.52734 20.4726 4.52734 19.3681V4.64844Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.4276 11.7224V13.4307H47.1339V11.7224H45.4276ZM44.7451 11.6797C44.7451 11.3259 45.0316 11.0391 45.385 11.0391H47.1766C47.5299 11.0391 47.8164 11.3259 47.8164 11.6797V13.4734C47.8164 13.8272 47.5299 14.114 47.1766 14.114H45.385C45.0316 14.114 44.7451 13.8272 44.7451 13.4734V11.6797Z"
          fill="#4F5B94"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.4276 15.4802V17.1885H47.1339V15.4802H45.4276ZM44.7451 15.4375C44.7451 15.0837 45.0316 14.7969 45.385 14.7969H47.1766C47.5299 14.7969 47.8164 15.0837 47.8164 15.4375V17.2312C47.8164 17.585 47.5299 17.8718 47.1766 17.8718H45.385C45.0316 17.8718 44.7451 17.585 44.7451 17.2312V15.4375Z"
          fill="#4F5B94"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.1815 11.7224V13.4307H50.8878V11.7224H49.1815ZM48.499 11.6797C48.499 11.3259 48.7855 11.0391 49.1389 11.0391H50.9305C51.2838 11.0391 51.5703 11.3259 51.5703 11.6797V13.4734C51.5703 13.8272 51.2838 14.114 50.9305 14.114H49.1389C48.7855 14.114 48.499 13.8272 48.499 13.4734V11.6797Z"
          fill="#4F5B94"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.8403 14.7969C49.0287 14.7969 49.1815 14.9498 49.1815 15.1385V16.1635C49.1815 16.3522 49.0287 16.5052 48.8403 16.5052C48.6518 16.5052 48.499 16.3522 48.499 16.1635V15.1385C48.499 14.9498 48.6518 14.7969 48.8403 14.7969Z"
          fill="#4F5B94"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.2053 14.7969C50.3938 14.7969 50.5466 14.9498 50.5466 15.1385V17.5302C50.5466 17.7189 50.3938 17.8718 50.2053 17.8718H48.8403C48.6518 17.8718 48.499 17.7189 48.499 17.5302C48.499 17.3415 48.6518 17.1885 48.8403 17.1885H49.864V15.1385C49.864 14.9498 50.0168 14.7969 50.2053 14.7969Z"
          fill="#4F5B94"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.8643 15.8231C49.8643 15.6344 50.017 15.4814 50.2055 15.4814H51.2293C51.4177 15.4814 51.5705 15.6344 51.5705 15.8231C51.5705 16.0118 51.4177 16.1648 51.2293 16.1648H50.2055C50.017 16.1648 49.8643 16.0118 49.8643 15.8231Z"
          fill="#4F5B94"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.229 16.5059C51.4174 16.5059 51.5702 16.6588 51.5702 16.8475V17.5308C51.5702 17.7195 51.4174 17.8725 51.229 17.8725C51.0405 17.8725 50.8877 17.7195 50.8877 17.5308V16.8475C50.8877 16.6588 51.0405 16.5059 51.229 16.5059Z"
          fill="#4F5B94"
        />
        <path
          d="M4.44043 4.64844C4.44043 3.54387 5.33586 2.64844 6.44043 2.64844H17.9097V21.3681H6.44043C5.33586 21.3681 4.44043 20.4726 4.44043 19.3681V4.64844Z"
          fill="#4F5B94"
        />
        <ellipse cx="11.1755" cy="8.65507" rx="3.34931" ry="3.35332" fill="white" />
        <path
          d="M4.44043 11.8682L17.1479 19.9774C17.6225 20.2802 17.9097 20.8043 17.9097 21.3674H6.44043C5.33586 21.3674 4.44043 20.4719 4.44043 19.3674V11.8682Z"
          fill="#AEAFC7"
        />
        <path
          d="M17.9102 10.4521L4.44084 19.5062C4.44084 20.5346 5.27451 21.3682 6.3029 21.3682H17.9102V10.4521Z"
          fill="#E2E2FC"
        />
        <rect x="21.7266" y="6.97266" width="19.5421" height="2.9781" fill="#E2E2FC" />
        <rect x="21.7266" y="11.8682" width="14.4112" height="1.17235" fill="#E2E2FC" />
        <rect x="21.7266" y="13.8691" width="16.6746" height="1.17235" fill="#E2E2FC" />
        <rect x="21.7266" y="15.8701" width="15.1659" height="1.17235" fill="#E2E2FC" />
        <path
          d="M48.8369 2.50684L55.5594 9.23741H50.8369C49.7323 9.23741 48.8369 8.34198 48.8369 7.23741V2.50684Z"
          fill="#4F5B94"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3739_82034"
          x="0.44043"
          y="0.506836"
          width="59.1191"
          height="26.8613"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0745098 0 0 0 0 0.0588235 0 0 0 0 0.14902 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3739_82034" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3739_82034" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

import React from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/core";
import { Checked } from "components/icons/components/Checked";
import { styles } from "./styles";

interface FlyerTypeData {
  dimensions: string;
  label: string;
  worth: string;
  type: string;
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

const useStyles = makeStyles(() => styles);

export const FlyerTypeCard = ({
  flyerTypeData,
  selectedFlyerType,
  onClick,
}: {
  flyerTypeData: FlyerTypeData;
  selectedFlyerType: string;
  onClick: () => void;
}) => {
  const classes = useStyles();
  const { Icon, dimensions, label, worth, type } = flyerTypeData;
  const selected = selectedFlyerType === type;

  return (
    <div className={cn(classes.flyerTypeCardContainer, { [classes.selected]: selected })} onClick={onClick}>
      <div className={classes.flyerTypeImageContainer}>
        {selected && <Checked className={classes.checked} />}
        <Icon />
      </div>
      <div className={classes.flyerTypeDataContainer}>
        <span className={classes.flyerTypeDimensions}>{dimensions}</span>
        <span className={classes.flyerTypeLabel}>{label}</span>
        <span className={classes.flyerTypeWorth}>{worth}</span>
      </div>
    </div>
  );
};

import cn from "classnames";
import { Button, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { styles } from "./style";

function CheckoutSidebar({ classes, show, children, onClose }) {
  return (
    <>
      {show && <div className={cn(classes.darkScreen)} />}
      <div className={`${cn(classes.sidebar)} ${show ? cn(classes.sidebarOpen) : ""}`}>
        <Button className={cn(classes.closeButton)} color="inherit" size="small" onClick={onClose}>
          <CloseIcon fontSize="default" />
        </Button>
        <div className={classes.content}>{children}</div>
      </div>
    </>
  );
}

export default withStyles(styles)(CheckoutSidebar);

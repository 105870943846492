import { getCities } from "../graphQL";

import { CAMPAIGN_ENUM_TYPES, CITY_STATUS_ACTIVE } from "../shared/constants";
import { convertArrayToHashMap } from "../shared/libraries/dataSets";
import { directMailOnlyCities } from "../constants";
import { platformUSACities } from "../pages/constants";

const getFilteredCities = async ({ country, channel }) => {
  let filter;

  if (channel === CAMPAIGN_ENUM_TYPES.LETTERBOX) {
    filter = "enabledChannelLetterbox";
  }

  if (channel === CAMPAIGN_ENUM_TYPES.HANDTOHAND) {
    filter = "enabledChannelPreciseLocations";
  }

  const cities = await getCities({
    status: CITY_STATUS_ACTIVE,
    countryId: country.id,
    includeSalesTax: channel !== CAMPAIGN_ENUM_TYPES.DIRECTMAIL,
  });

  const hashMapCitiesSettings = convertArrayToHashMap(country.citiesSettings, "id");

  return cities.reduce((acc, city) => {
    if (!directMailOnlyCities.includes(city.name)) {
      const settings = hashMapCitiesSettings[city.id];
      if (settings && settings[filter]) {
        acc.push({
          id: Number(city.id),
          lat: city.location.latitude,
          lng: city.location.longitude,
          name: city.name,
          nameWithState: platformUSACities[city.name] ? `${city.name}, ${platformUSACities[city.name]}` : city.name,
          radius: 3,
          addresses: [],
          isAddressesPopupOpened: false,
          isCityOptionsOpened: false,
          displayCity: true,
          isVisible: true,
          salesTaxRate: city.salesTaxRate,
          postalCode: city.postalCode,
        });
      }
    }
    return acc;
  }, []);
};

export { getFilteredCities };

import cn from "classnames";
import { SelectedRoute, SuggestedRoute } from "module/eddm/dto";
import { formatNumber, toFixed2 } from "utils/formators";
import { GoogleMap } from "@react-google-maps/api";
import { RouteIcon } from "components/icons/components/RouteIcon";
import { ResidencesIcon } from "components/icons/components/ResidencesIcon";
import { updateSuggestedRoutesOnDataLayer } from "components/audience-map/utils";
import { makeStyles } from "@material-ui/core";
import { SuggestionStart } from "static/media";
import { useStore } from "store";
import { styles } from "./styles";
import { SuggestedRouteCard } from "./components/index";

const useStyles = makeStyles(() => styles);

interface SuggestionAddressCardProps {
  suggestedRoutes: SuggestedRoute[];
  setSelectedRoutes: (selectedRoutes: SelectedRoute[] | ((selectedRoutes: SelectedRoute[]) => SelectedRoute[])) => void;
  googleMap: GoogleMap | null;
}

export const SuggestedRoutesBlock = ({ suggestedRoutes, setSelectedRoutes, googleMap }: SuggestionAddressCardProps) => {
  const classes = useStyles();
  const {
    costsCalculationData: { costPerFlyer },
    setSuggestedRoutes,
    setSuggestionsEnabled,
    suggestionsEnabled,
  } = useStore();
  // const posthog = usePostHog();
  // const posthogEventTracker = new EmitAnalyticsEvent(posthog);

  const totalSuggestedAddresses = suggestedRoutes.reduce((acc, suggestedRoute) => {
    acc += suggestedRoute.properties.APT_COUNT + suggestedRoute.properties.HOME_COUNT;
    return acc;
  }, 0);

  const handleRouteDelete = (route: SuggestedRoute) => {
    if (!route || !googleMap) return;
    const updatedRoutes = suggestedRoutes.filter((suggestedRoute) => suggestedRoute._id !== route._id);
    let newSuggestionsEnabled = suggestionsEnabled;
    if (!updatedRoutes.length) {
      newSuggestionsEnabled = false;
      setSuggestionsEnabled(newSuggestionsEnabled);
    }
    // @ts-ignore
    updateSuggestedRoutesOnDataLayer({
      // @ts-ignore
      dataLayer: googleMap.data,
      // @ts-ignore
      suggestedRoutes: updatedRoutes,
      suggestionsEnabled: newSuggestionsEnabled,
    });
    setSuggestedRoutes(updatedRoutes);
  };

  return (
    <div className={cn(classes.addressCardContainer)}>
      <span className={classes.topTriangle}></span>
      <div className={classes.addressHeader}>
        <div className={cn(classes.addressTitle)}>
          <SuggestionStart stroke="#EE4360" />
          <span className={classes.name}>Suggested routes</span>
        </div>
      </div>
      <div
        className={cn(classes.addressStatistics, {
          [classes.addressStatisticsEmpty]: !suggestedRoutes?.length,
        })}
      >
        <div className={classes.statisticsValueContainer}>
          <span className={cn(classes.statisticLabel)}>Routes</span>
          <span className={cn(classes.statisticsValue)}>
            <RouteIcon stroke="#2E3860" /> {suggestedRoutes?.length || 0}
          </span>
        </div>
        <div className={classes.statisticsValueContainer}>
          <span className={cn(classes.statisticLabel)}>Residences</span>
          <span className={cn(classes.statisticsValue)}>
            <ResidencesIcon stroke="#2E3860" />
            {formatNumber(totalSuggestedAddresses)}
          </span>
        </div>
        <div className={cn(classes.statisticsValueContainer, classes.statisticsCostValue)}>
          <span className={cn(classes.statisticLabel, classes.costLabel)}>Costs</span>
          <span className={cn(classes.costValue)}>
            $ {formatNumber(toFixed2(costPerFlyer * totalSuggestedAddresses))}
          </span>
        </div>
      </div>
      {!!suggestedRoutes?.length &&
        suggestedRoutes.map((route, i) => (
          <SuggestedRouteCard
            key={`${route.properties.ZIP}-${route.properties.CROUTE}`}
            route={route}
            className={cn({
              [classes.firstRoute]: i === 0,
              [classes.lastRoute]: i === suggestedRoutes.length - 1,
            })}
            handleRouteDelete={handleRouteDelete}
          />
        ))}
    </div>
  );
};

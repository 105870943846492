import { IconsProps } from "shared/models";

export const Language = ({ width = "15", height = "15", fill = "none", stroke = "#2E3860", ...props }: IconsProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.5732 20.0618L19.3315 16.7256M12.8281 20.0618L14.0573 16.7256M14.0573 16.7256L16.2782 10.6975H17.0878L19.3315 16.7256M14.0573 16.7256H19.3315"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.83789 16.9989C6.06852 16.0012 7.99503 14.34 9.73112 12.2816M13.4274 5.87446H15.7511M13.4274 5.87446H9.73112M13.4274 5.87446C12.902 7.93439 11.4051 10.2968 9.73112 12.2816M3.42969 5.87446H9.73112M9.73112 5.87446V3.93823M9.73112 12.2816C9.07398 11.6398 7.62593 9.95106 7.09083 8.79637M9.73112 12.2816C10.3766 12.954 11.8434 14.3304 12.5474 14.9219"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

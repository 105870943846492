import React from "react";

export const JumboFlyerIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="57" height="48" viewBox="0 0 57 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_d_3739_98193)">
        <path
          d="M4.0166 6.81738C4.0166 4.60824 5.80746 2.81738 8.0166 2.81738H45.9446L52.5268 9.49195V37.0571C52.5268 39.2662 50.7359 41.0571 48.5268 41.0571H8.01661C5.80747 41.0571 4.0166 39.2662 4.0166 37.0571V6.81738Z"
          fill="white"
        />
        <path
          d="M4.0166 6.875C4.0166 4.66586 5.80746 2.875 8.0166 2.875L45.9993 2.875L52.3403 9.24713V33.8947H4.0166V6.875Z"
          fill="#E2E2FC"
        />
        <path
          d="M10.9443 27.4932C9.56362 27.4932 8.44434 28.6124 8.44434 29.9932V36.5373C8.44434 37.9181 9.56362 39.0373 10.9443 39.0373H45.6533C47.034 39.0373 48.1533 37.9181 48.1533 36.5373V29.9932C48.1533 28.6125 47.034 27.4932 45.6533 27.4932H10.9443Z"
          fill="#E2E2FC"
          stroke="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2925 30.8218V32.4417H14.9126V30.8218H13.2925ZM12.6445 30.7813C12.6445 30.4458 12.9165 30.1738 13.252 30.1738H14.9531C15.2886 30.1738 15.5606 30.4458 15.5606 30.7813V32.4822C15.5606 32.8177 15.2886 33.0897 14.9531 33.0897H13.252C12.9165 33.0897 12.6445 32.8177 12.6445 32.4822V30.7813ZM13.2925 34.3855V36.0054H14.9126V34.3855H13.2925ZM12.6445 34.345C12.6445 34.0095 12.9165 33.7375 13.252 33.7375H14.9531C15.2886 33.7375 15.5606 34.0095 15.5606 34.345V36.0459C15.5606 36.3814 15.2886 36.6534 14.9531 36.6534H13.252C12.9165 36.6534 12.6445 36.3814 12.6445 36.0459V34.345ZM16.857 32.4417V30.8218H18.477V32.4417H16.857ZM16.8165 30.1738C16.481 30.1738 16.209 30.4458 16.209 30.7813V32.4822C16.209 32.8177 16.481 33.0897 16.8165 33.0897H18.5175C18.853 33.0897 19.125 32.8177 19.125 32.4822V30.7813C19.125 30.4458 18.853 30.1738 18.5175 30.1738H16.8165ZM16.5332 33.7375C16.7121 33.7375 16.8572 33.8826 16.8572 34.0615V35.0335C16.8572 35.2124 16.7121 35.3575 16.5332 35.3575C16.3542 35.3575 16.2091 35.2124 16.2091 35.0335V34.0615C16.2091 33.8826 16.3542 33.7375 16.5332 33.7375ZM18.1532 34.0615C18.1532 33.8826 18.0081 33.7375 17.8292 33.7375C17.6502 33.7375 17.5052 33.8826 17.5052 34.0615V34.6965C17.505 34.7012 17.5049 34.7059 17.5049 34.7107C17.5049 34.7155 17.505 34.7203 17.5052 34.725V36.0054H16.5332C16.3542 36.0054 16.2091 36.1505 16.2091 36.3294C16.2091 36.5083 16.3542 36.6534 16.5332 36.6534H17.8292C18.0081 36.6534 18.1532 36.5083 18.1532 36.3294V35.0347H18.8009C18.9798 35.0347 19.1249 34.8897 19.1249 34.7107C19.1249 34.5318 18.9798 34.3867 18.8009 34.3867H18.1532V34.0615ZM19.1243 35.6818C19.1243 35.5028 18.9792 35.3578 18.8003 35.3578C18.6214 35.3578 18.4763 35.5028 18.4763 35.6818V36.3297C18.4763 36.5087 18.6214 36.6537 18.8003 36.6537C18.9792 36.6537 19.1243 36.5087 19.1243 36.3297V35.6818Z"
          fill="#4F5B94"
        />
        <path
          d="M9.99121 11.3223C9.99121 10.2177 10.8866 9.32227 11.9912 9.32227H21.462C22.5665 9.32227 23.462 10.2177 23.462 11.3223V22.1947H11.9912C10.8866 22.1947 9.99121 21.2992 9.99121 20.1947V11.3223Z"
          fill="#4F5B94"
        />
        <ellipse cx="16.7266" cy="14.3516" rx="3.34967" ry="3.38093" fill="white" />
        <path
          d="M9.99121 17.5918L22.9042 21.4464C23.2352 21.5452 23.462 21.8497 23.462 22.1951H9.99121V17.5918Z"
          fill="#AEAFC7"
        />
        <path
          d="M23.4619 16.1631L9.99115 22.1466C9.99115 22.8263 10.5421 23.3772 11.2217 23.3772H21.4619C22.5665 23.3772 23.4619 22.4818 23.4619 21.3772V16.1631Z"
          fill="white"
        />
        <rect x="28.2998" y="11.1934" width="15.6472" height="3.00263" fill="white" />
        <rect x="28.2998" y="16.1426" width="11.5389" height="1.182" fill="white" />
        <rect x="28.2998" y="18.1602" width="13.3512" height="1.182" fill="white" />
        <rect x="28.2998" y="20.1777" width="12.1432" height="1.182" fill="white" />
        <rect x="28.2998" y="22.1953" width="13.931" height="1.182" fill="white" />
        <rect x="23.5625" y="30.8057" width="21.3772" height="1.182" fill="white" />
        <rect x="23.5625" y="32.8232" width="19.4429" height="1.182" fill="white" />
        <rect x="23.5625" y="34.8408" width="19.4429" height="1.182" fill="white" />
        <path
          d="M46.1904 2.875L52.5273 9.35409H48.1904C47.0859 9.35409 46.1904 8.45866 46.1904 7.35409V2.875Z"
          fill="#4F5B94"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3739_98193"
          x="0.0166016"
          y="0.817383"
          width="56.5107"
          height="46.2393"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0745098 0 0 0 0 0.0588235 0 0 0 0 0.14902 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3739_98193" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3739_98193" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

import { IconsProps } from "shared/models";

export const Education = ({ width = "14", height = "14", fill = "none", stroke = "#2E3860", ...props }: IconsProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.1769 9.17816L11.9981 4.22534L2.73242 9.17816L5.84679 10.8429M21.1769 9.17816V16.3679M21.1769 9.17816L18.0409 10.7258M18.0409 10.7258L11.9981 14.1311L5.84679 10.8429M18.0409 10.7258V16.3679C18.0409 18.8443 14.0432 20.1224 11.9981 20.1224C9.9529 20.1224 5.84679 18.8443 5.84679 16.3679V10.8429"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

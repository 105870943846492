import { IconsProps } from "shared/models";

export const Income = ({
  width = "13",
  height = "11",
  fill = "none",
  isActive = false,
  stroke = "#2E3860",
  ...props
}: IconsProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.99916 18.5713C14.7331 18.5713 18.5706 14.7338 18.5706 9.99989C18.5706 5.26603 14.7331 1.42847 9.99916 1.42847C5.26529 1.42847 1.42773 5.26603 1.42773 9.99989C1.42773 14.7338 5.26529 18.5713 9.99916 18.5713Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2448 7.65579C12.1677 7.43742 12.0486 7.23882 11.8972 7.06972C11.5752 6.71001 11.1074 6.48364 10.5867 6.48364H9.22572C8.35892 6.48364 7.65625 7.18632 7.65625 8.0531C7.65625 8.79065 8.16981 9.42869 8.89033 9.58632L10.9624 10.0396C11.7696 10.2161 12.3449 10.9314 12.3449 11.7577C12.3449 12.7288 11.5577 13.5166 10.5867 13.5166H9.41449C8.64894 13.5166 7.99767 13.0273 7.7563 12.3444"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10 6.48333V4.7251" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 15.2743V13.5161" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

import { IconsProps } from "shared/models";

export const Add = ({
  width = "13",
  height = "12",
  fill,
  isActive = false,
  hoverColor = "#dedede",
  ...props
}: IconsProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 12"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.5 6H9.5"
        stroke={isActive ? hoverColor : "#2E3860"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 9V3"
        stroke={isActive ? hoverColor : "#2E3860"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import { useCallback } from "react";
import { toFixed2 } from "utils/formators";
import { calculateRouteFitScorePercentage } from "components/audience-map/utils";
import { getDMPrintingCostByFlyer } from "../../shared/campaign";
import { StoreType, CostsCalculationDataType, Route } from "../../store/types";
import { calculateTaxAndTotalCost } from "./utils";
import { getDMFlyersAmount } from "../../dm-campaign-data-hoc/utils";
import { useStore } from "../../store";
import { CAMPAIGN_INTERNAL_STATUSES } from "../../pages/constants";

const calculateTotalsOfIntercectedRoutes = (intercectedRoutes: Route[], selectedTargets: any[]) => {
  return intercectedRoutes.reduce(
    (acc: { flyers: number; business: number; routes: number; fitScores: number[] }, curr: Route) => {
      acc.routes = acc.routes + 1;
      acc.business = acc.business + curr?.metadata?.BUSINESS_C || 0;
      acc.flyers = acc.flyers + (curr?.metadata?.HOME_COUNT || 0) + (curr?.metadata?.APT_COUNT || 0);
      acc.fitScores.push(calculateRouteFitScorePercentage(curr.metadata, selectedTargets));
      acc.fitScores.sort((a: number, b: number) => a - b);
      return acc;
    },
    { flyers: 0, business: 0, routes: 0, fitScores: [] }
  );
};

export const useEDDMCostsCalculationV2 = (): {
  recalculateEDDMCampaignCost: () => void;
  calculateIntercectedRoutesCost: (
    intercectedRoutes: Route[],
    selectedTargets: any[]
  ) => {
    cost: number;
    business: number;
    routes: number;
    flyers: number;
  };
} => {
  const {
    campaign: { flyerType, internalStatus },
    country,
    costsCalculationData,
    distributionLocations = [],
    updateCostsCalculationData,
  }: StoreType = useStore();

  const { printingCosts, detailedCost }: CostsCalculationDataType = costsCalculationData;
  const { amountOff = 0, percentOff } = detailedCost || {};
  const countryTaxRate = country?.countryDefaultSettings?.countryTaxRate || 0;

  const recalculateEDDMCampaignCost = useCallback(() => {
    if (internalStatus !== CAMPAIGN_INTERNAL_STATUSES.DRAFT) {
      return;
    }

    const flyersAmount =
      distributionLocations && distributionLocations.length ? getDMFlyersAmount(distributionLocations) : 0;

    const costPerFlyer = getDMPrintingCostByFlyer({
      printingCosts,
      flyerType,
      flyersAmount,
    });

    const subtotal = flyersAmount * costPerFlyer;
    const fixed2Subtotal = toFixed2(subtotal);

    const { tax, totalCost } = calculateTaxAndTotalCost({ countryTaxRate, subtotal: fixed2Subtotal, amountOff });

    updateCostsCalculationData({
      ...costsCalculationData,
      costPerFlyer,
      flyersAmount,
      detailedCost: {
        subtotal: fixed2Subtotal,
        tax: toFixed2(tax),
        totalCost: toFixed2(totalCost),
        amountOff,
        percentOff,
      },
      settings: { countryTaxRate },
    });
  }, [flyerType, distributionLocations, internalStatus, amountOff, percentOff]);

  const calculateIntercectedRoutesCost = useCallback(
    (intercectedRoutes, selectedTargets) => {
      const flyersAmount =
        distributionLocations && distributionLocations.length ? getDMFlyersAmount(distributionLocations) : 0;

      const { flyers, business, routes, fitScores } = calculateTotalsOfIntercectedRoutes(
        intercectedRoutes,
        selectedTargets
      );

      const fitScoreRange =
        fitScores.length === 1 || fitScores[0] === fitScores[fitScores.length - 1]
          ? `${fitScores[0]}%`
          : fitScores.length
          ? `${fitScores[fitScores.length - 1]}-${fitScores[0]}%`
          : 0;

      const costPerFlyer = getDMPrintingCostByFlyer({
        printingCosts,
        flyerType,
        flyersAmount: flyersAmount + flyers,
      });
      return { cost: flyers * costPerFlyer, business, routes, flyers, fitScore: fitScoreRange };
    },
    [flyerType, distributionLocations, internalStatus, amountOff, percentOff]
  );

  return { recalculateEDDMCampaignCost, calculateIntercectedRoutesCost };
};

export const styles = {
  addressCardContainer: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
    marginBottom: "25px",
    position: "relative" as const,
    padding: "10px 17px",
    backgroundColor: "#F2F2FE",
    borderRadius: "8px",
    margin: "0px 8px",
  },
  topTriangle: {
    width: "0",
    height: "0",
    borderLeft: "20px solid transparent",
    borderRight: "20px solid transparent",
    borderBottom: "20px solid #F2F2FE",
    position: "absolute" as const,
    top: "-3%",
    left: "45%",
  },
  deletingOverlap: {
    position: "absolute" as const,
    top: "0px",
    right: "0px",
    borderRadius: "8px",
    width: "0%",
    height: "100%",
    background: "#E6254D",
    transition: "width 0.2s ease-out",
  },
  deletingAnimation: {
    width: "100%",
  },
  addressHeader: {
    width: "100%",
    minHeight: "30px",
    display: "flex",
    marginBottom: "5px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  addressTitle: {
    display: "flex",
    alignItems: "center",
    fontFamily: "'Proxima Nova', 'sans-serif'",
    maxWidth: "90%",
    cursor: "pointer",
  },
  shortTitle: {
    maxWidth: "65%",
  },
  name: {
    marginLeft: "5px",
    fontWeight: 400,
    fontFamily: "'Proxima Nova', 'sans-serif'",
    whiteSpace: "nowrap" as const,
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#011533",
    fontSize: "14px",
  },
  deleteConfirmationButtonsWrapper: {
    display: "flex",
  },
  noButton: {
    borderRadius: "4px",
    padding: "2px 15px",
    fontWeight: 800,
    fontSize: "12px",
    fontFamily: "'Proxima Nova', 'sans-serif'",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FFC9D3",
    },
  },
  yesButton: {
    borderRadius: "4px",
    padding: "2px 15px",
    backgroundColor: "#EE4360",
    marginLeft: "4px",
    color: "#FFFFFF",
    fontSize: "12px",
    fontWeight: 800,
    fontFamily: "'Proxima Nova', 'sans-serif'",
    cursor: "pointer",
  },

  addressStatistics: {
    width: "100%",
    position: "relative" as const,
    border: "1px solid transparent",

    display: "flex",
    justifyContent: "space-between",
    fontFamily: "'Proxima Nova', 'sans-serif'",
    fontSize: "14px",
    alignItems: "center",
    paddingBottom: "12px",
  },
  addressStatisticsCollapsed: {
    padding: "12px",
    background: "#FFF5F9",
    border: "1px solid #FFD7F4",
    borderRadius: "8px",
    boxShadow: "#FFF5F9 0px 3px 0px -1px, #FFD7F4 0px 3px, #FFF5F9 0px 6px 0px -1px, #FFD7F4 0px 6px",
  },

  addressStatisticsEmpty: {
    padding: "12px",
    background: "#FCFCFF",
    border: "1px solid #F0F0FF",
    borderRadius: "8px",
  },

  addressStatisticsCollapsedDeleting: {
    border: "1px solid #E00404",
    backgroundColor: "#FF798D1F",
    boxShadow: "#F9F9FF 0px 3px 0px -1px, #E00404 0px 3px, #F9F9FF 0px 6px 0px -1px, #E00404 0px 6px",
  },
  statisticsValueContainer: {
    display: "flex",
    flexDirection: "column" as const,
  },
  statisticsCostValue: {
    alignItems: "flex-end",
  },
  statisticLabel: {
    color: "#4F5B94",
    fontSize: "12px",
  },
  statisticsValue: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 500,
    "& svg": {
      marginRight: "2px",
    },
    color: "#2E3860",
  },
  costLabel: {
    color: "#4F5B94",
  },
  costValue: {
    fontWeight: 700,
    color: "#2E3860",
  },
  disabled: {
    color: "#AEAFC7",
  },
  deletingLabel: {
    color: "red",
  },
  buttonsContainer: {
    display: "flex",
  },
  arrow: {
    color: "black",
  },
  tooltip: {
    backgroundColor: "black",
    fontSize: "14px",
    fontWeight: 700,
    padding: "8px 16px",
  },
  iconButton: {
    cursor: "pointer",
    border: "1px solid #E2E2FC",
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  expandCollapseButton: {
    marginRight: "5px",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  firstRoute: {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
  lastRoute: {
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
  },
  firstRouteDeleting: {
    borderTop: "1px solid #E00404 !important",
  },
  lastRouteDeleting: {
    borderBottom: "1px solid #E00404 !important",
  },
};

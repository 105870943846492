import React from "react";

export const OversizedFlyerIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="75" height="62" viewBox="0 0 75 62" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_d_3739_72793)">
        <path
          d="M4 6.70214C4 4.49301 5.79086 2.70215 8 2.70215H63.4619L70.0535 9.24802V51.2365C70.0535 53.4457 68.2627 55.2365 66.0535 55.2365H8C5.79086 55.2365 4 53.4457 4 51.2365V6.70214Z"
          fill="#E2E2FC"
        />
        <path
          d="M42.6768 37.2324C42.6768 36.1279 43.5722 35.2324 44.6768 35.2324H68.061C69.1656 35.2324 70.061 36.1279 70.061 37.2324V50.1074C70.061 51.212 69.1656 52.1074 68.061 52.1074H44.6768C43.5722 52.1074 42.6768 51.212 42.6768 50.1074V37.2324Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4558 43.9254V45.5112H14.0593V43.9254H12.4558ZM11.8145 43.8857C11.8145 43.5573 12.0837 43.291 12.4158 43.291H14.0994C14.4315 43.291 14.7007 43.5573 14.7007 43.8857V45.5508C14.7007 45.8793 14.4315 46.1455 14.0994 46.1455H12.4158C12.0837 46.1455 11.8145 45.8793 11.8145 45.5508V43.8857ZM12.4558 47.414V48.9999H14.0593V47.414H12.4558ZM11.8145 47.3744C11.8145 47.046 12.0837 46.7797 12.4158 46.7797H14.0994C14.4315 46.7797 14.7007 47.046 14.7007 47.3744V49.0395C14.7007 49.368 14.4315 49.6342 14.0994 49.6342H12.4158C12.0837 49.6342 11.8145 49.368 11.8145 49.0395V47.3744ZM15.984 45.5112V43.9254H17.5875V45.5112H15.984ZM15.944 43.291C15.6119 43.291 15.3427 43.5573 15.3427 43.8857V45.5508C15.3427 45.8793 15.6119 46.1455 15.944 46.1455H17.6276C17.9597 46.1455 18.2289 45.8793 18.2289 45.5508V43.8857C18.2289 43.5573 17.9597 43.291 17.6276 43.291H15.944ZM15.6636 46.7797C15.8409 46.7797 15.9846 46.9217 15.9846 47.0969V48.0484C15.9846 48.2235 15.8409 48.3655 15.6636 48.3655C15.4864 48.3655 15.3427 48.2235 15.3427 48.0484V47.0969C15.3427 46.9217 15.4864 46.7797 15.6636 46.7797ZM17.2668 47.0969C17.2668 46.9217 17.1232 46.7797 16.9461 46.7797C16.769 46.7797 16.6254 46.9217 16.6254 47.0969V47.7184C16.6252 47.7231 16.6251 47.7277 16.6251 47.7324C16.6251 47.7371 16.6252 47.7418 16.6254 47.7464V48.9999H15.6633C15.4862 48.9999 15.3427 49.1419 15.3427 49.317C15.3427 49.4922 15.4862 49.6342 15.6633 49.6342H16.9461C17.1232 49.6342 17.2668 49.4922 17.2668 49.317V48.0496H17.9079C18.085 48.0496 18.2286 47.9076 18.2286 47.7324C18.2286 47.5573 18.085 47.4153 17.9079 47.4153H17.2668V47.0969ZM18.2286 48.683C18.2286 48.5078 18.0849 48.3658 17.9076 48.3658C17.7304 48.3658 17.5866 48.5078 17.5866 48.683V49.3173C17.5866 49.4925 17.7304 49.6345 17.9076 49.6345C18.0849 49.6345 18.2286 49.4925 18.2286 49.3173V48.683Z"
          fill="#4F5B94"
        />
        <path
          d="M7.73633 11.6355C7.73633 9.25555 9.6657 7.32617 12.0457 7.32617H32.4522C34.8322 7.32617 36.7616 9.25554 36.7616 11.6355V35.2329H12.0457C9.6657 35.2329 7.73633 33.3036 7.73633 30.9236V11.6355Z"
          fill="#4F5B94"
        />
        <ellipse cx="22.2497" cy="17.9342" rx="7.21749" ry="7.13151" fill="white" />
        <path
          d="M7.73633 24.7686L31.7866 35.7095C32.223 35.908 32.5031 36.3432 32.5031 36.8225C32.5031 37.4494 31.9949 37.9575 31.3681 37.9575H12.0457C9.6657 37.9575 7.73633 36.0282 7.73633 33.6482V24.7686Z"
          fill="#AEAFC7"
        />
        <path
          d="M36.7617 21.7549L7.73643 33.9971C7.73643 36.1842 9.50941 37.9572 11.6965 37.9572H32.4523C34.8323 37.9572 36.7617 36.0278 36.7617 33.6478V21.7549Z"
          fill="white"
        />
        <rect x="42.6768" y="12.2354" width="15.6472" height="2.93943" fill="white" />
        <rect x="42.6768" y="17.0801" width="11.5389" height="1.15712" fill="white" />
        <rect x="42.6768" y="24.9805" width="11.5389" height="1.15712" fill="white" />
        <rect x="42.6768" y="19.0557" width="13.3512" height="1.15712" fill="white" />
        <rect x="42.6768" y="26.9561" width="13.3512" height="1.15712" fill="white" />
        <rect x="42.6768" y="21.0303" width="12.1432" height="1.15712" fill="white" />
        <rect x="42.6768" y="28.9307" width="12.1432" height="1.15712" fill="white" />
        <rect x="42.6768" y="23.0059" width="13.931" height="1.15712" fill="white" />
        <rect x="42.6768" y="30.9062" width="13.931" height="1.15712" fill="white" />
        <rect x="46.9961" y="39.6182" width="15.6472" height="1.56834" fill="#E2E2FC" />
        <rect x="46.9961" y="43.0918" width="11.5389" height="1.15712" fill="#E2E2FC" />
        <rect x="46.9961" y="45.0674" width="13.3512" height="1.15712" fill="#E2E2FC" />
        <rect x="46.9961" y="47.042" width="12.1432" height="1.15712" fill="#E2E2FC" />
        <rect x="22.249" y="43.9082" width="11.4839" height="1.15712" fill="white" />
        <rect x="22.249" y="45.8838" width="10.4448" height="1.15712" fill="white" />
        <rect x="22.249" y="47.8584" width="10.4448" height="1.15712" fill="white" />
        <path
          d="M63.7236 2.63867L70.0605 8.98139H65.7236C64.6191 8.98139 63.7236 8.08596 63.7236 6.98139V2.63867Z"
          fill="#4F5B94"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3739_72793"
          x="0"
          y="0.638672"
          width="74.0605"
          height="60.5977"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0745098 0 0 0 0 0.0588235 0 0 0 0 0.14902 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3739_72793" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3739_72793" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

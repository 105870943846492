export const styles = {
  addressCardContainer: {
    borderRadius: "8px",
    border: "1px solid #E2E2FC",
    padding: "5px 8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
    cursor: "pointer",
    height: "66px",
    position: "relative",
  },
  deletingOverlap: {
    position: "absolute",
    top: "0px",
    right: "0px",
    borderRadius: "8px",
    width: "0%",
    height: "100%",
    background: "#E6254D",
    transition: "width 0.2s ease-out",
  },
  deletingAnimation: {
    width: "100%",
  },
  addressHeader: {
    width: "100%",
    minHeight: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  addressTitle: {
    display: "flex",
    alignItems: "center",
    fontFamily: "'Proxima Nova', 'sans-serif'",
    maxWidth: "90%",
  },
  shortTitle: {
    maxWidth: "65%",
  },
  name: {
    marginLeft: "5px",
    fontWeight: 400,
    fontFamily: "'Proxima Nova', 'sans-serif'",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // maxWidth: "40%",
  },
  focused: {
    backgroundColor: "#E2E2FC",
  },
  deleting: {
    backgroundColor: "#FCE3E8",
    border: "1px solid #FFA4B5",
  },
  deleteConfirmationButtonsWrapper: {
    display: "flex",
  },
  noButton: {
    borderRadius: "4px",
    padding: "4px 11px",
    fontWeight: 800,
    fontSize: "14px",
    fontFamily: "'Proxima Nova', 'sans-serif'",
    "&:hover": {
      backgroundColor: "#FFC9D3",
    },
  },
  yesButton: {
    borderRadius: "4px",
    padding: "4px 11px",
    backgroundColor: "#EE4360",
    marginLeft: "4px",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: 800,
    fontFamily: "'Proxima Nova', 'sans-serif'",
  },

  addressStatistics: {
    width: "100%",
    marginTop: "5px",
    display: "grid",
    gridTemplateColumns: "1.2fr 1.7fr 1.6fr",
    fontFamily: "'Proxima Nova', 'sans-serif'",
    fontSize: "12px",
  },
  statisticLabel: {
    color: "#707087",
  },
};

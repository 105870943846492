import React from "react";

export const BestSellerFlyerIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="48" height="36" viewBox="0 0 48 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_d_3739_82061)">
        <path
          d="M4.2168 4.31836C4.2168 3.21379 5.11223 2.31836 6.2168 2.31836H36.257C36.7877 2.31836 37.2966 2.52926 37.6717 2.90463L43.6673 8.90436V27.6974C43.6673 28.8019 42.7719 29.6974 41.6673 29.6974H6.21679C5.11223 29.6974 4.2168 28.8019 4.2168 27.6974V4.31836Z"
          fill="white"
        />
        <path
          d="M6.65918 8.09863C6.65918 6.99406 7.55461 6.09863 8.65918 6.09863H18.2698C19.3744 6.09863 20.2698 6.99406 20.2698 8.09863V19.2591H8.65918C7.55461 19.2591 6.65918 18.3637 6.65918 17.2591V8.09863Z"
          fill="#4F5B94"
        />
        <ellipse cx="13.4645" cy="11.1395" rx="3.38445" ry="3.3885" fill="white" />
        <path d="M6.65918 14.3867L20.2698 18.3604V19.223H6.65918V14.3867Z" fill="#AEAFC7" />
        <path
          d="M20.2695 12.9541L6.65891 18.5132C6.65891 19.5524 7.50133 20.3948 8.54051 20.3948H18.2695C19.3741 20.3948 20.2695 19.4994 20.2695 18.3948V12.9541Z"
          fill="#E2E2FC"
        />
        <path
          d="M4.2168 24.8135H43.6673V27.6974C43.6673 28.8019 42.7719 29.6974 41.6673 29.6974H6.21679C5.11223 29.6974 4.2168 28.8019 4.2168 27.6974V24.8135Z"
          fill="#E2E2FC"
        />
        <rect x="33.9639" y="21.54" width="7.06143" height="6.98037" rx="1" fill="#E2E2FC" />
        <rect x="9.49414" y="26.9727" width="20.2667" height="0.94912" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.3653 22.9489V24.2812H36.696V22.9489H35.3653ZM34.833 22.9156C34.833 22.6397 35.0564 22.416 35.332 22.416H36.7293C37.0048 22.416 37.2283 22.6397 37.2283 22.9156V24.3145C37.2283 24.5905 37.0048 24.8141 36.7293 24.8141H35.332C35.0564 24.8141 34.833 24.5905 34.833 24.3145V22.9156ZM35.3653 25.8799V27.2121H36.696V25.8799H35.3653ZM34.833 25.8465C34.833 25.5706 35.0564 25.3469 35.332 25.3469H36.7293C37.0048 25.3469 37.2283 25.5706 37.2283 25.8465V27.2455C37.2283 27.5214 37.0048 27.7451 36.7293 27.7451H35.332C35.0564 27.7451 34.833 27.5214 34.833 27.2455V25.8465ZM38.2927 24.2812V22.9489H39.6234V24.2812H38.2927ZM38.2594 22.416C37.9838 22.416 37.7604 22.6397 37.7604 22.9156V24.3145C37.7604 24.5905 37.9838 24.8141 38.2594 24.8141H39.6567C39.9323 24.8141 40.1557 24.5905 40.1557 24.3145V22.9156C40.1557 22.6397 39.9323 22.416 39.6567 22.416H38.2594ZM38.0266 25.3469C38.1736 25.3469 38.2927 25.4662 38.2927 25.6134V26.4128C38.2927 26.5599 38.1736 26.6792 38.0266 26.6792C37.8796 26.6792 37.7604 26.5599 37.7604 26.4128V25.6134C37.7604 25.4662 37.8796 25.3469 38.0266 25.3469ZM39.3573 25.6134C39.3573 25.4662 39.2381 25.3469 39.0911 25.3469C38.9441 25.3469 38.825 25.4662 38.825 25.6134V27.2121H38.0266C37.8796 27.2121 37.7604 27.3314 37.7604 27.4786C37.7604 27.6258 37.8796 27.7451 38.0266 27.7451H39.0911C39.2381 27.7451 39.3573 27.6258 39.3573 27.4786V26.4141H39.8898C40.0368 26.4141 40.156 26.2948 40.156 26.1477C40.156 26.0005 40.0368 25.8812 39.8898 25.8812H39.3573V25.6134ZM40.1556 26.9456C40.1556 26.7985 40.0365 26.6792 39.8895 26.6792C39.7425 26.6792 39.6233 26.7985 39.6233 26.9456V27.4785C39.6233 27.6257 39.7425 27.745 39.8895 27.745C40.0365 27.745 40.1556 27.6257 40.1556 27.4785V26.9456Z"
          fill="#4F5B94"
        />
        <rect x="23.8633" y="8.31055" width="15.6455" height="2.97811" fill="#E2E2FC" />
        <rect x="23.8633" y="13.2188" width="11.5376" height="1.17235" fill="#E2E2FC" />
        <rect x="23.8633" y="15.2197" width="13.3498" height="1.17235" fill="#E2E2FC" />
        <rect x="23.8633" y="19.2217" width="13.3498" height="1.17235" fill="#E2E2FC" />
        <rect x="23.8633" y="17.2207" width="12.1419" height="1.17235" fill="#E2E2FC" />
        <path
          d="M37.0615 2.17676L43.7841 8.90733H39.0615C37.957 8.90733 37.0615 8.0119 37.0615 6.90733V2.17676Z"
          fill="#4F5B94"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3739_82061"
          x="0.216797"
          y="0.176758"
          width="47.5674"
          height="35.5205"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0745098 0 0 0 0 0.0588235 0 0 0 0 0.14902 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3739_82061" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3739_82061" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

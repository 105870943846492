import { IconsProps } from "shared/models";

export const Edit = ({
  width = "13",
  height = "12",
  fill,
  isActive = false,
  hoverColor = "#dedede",
  ...props
}: IconsProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 12"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.25 10.2499L11 3.49991C11.1313 3.36859 11.2355 3.21268 11.3066 3.0411C11.3776 2.86952 11.4142 2.68562 11.4142 2.49991C11.4142 2.31419 11.3776 2.13029 11.3066 1.95871C11.2355 1.78713 11.1313 1.63123 11 1.49991C10.8687 1.36859 10.7128 1.26441 10.5412 1.19334C10.3696 1.12227 10.1857 1.08569 10 1.08569C9.81428 1.08569 9.63038 1.12227 9.4588 1.19334C9.28722 1.26441 9.13132 1.36859 9 1.49991L2.25 8.24991M4.25 10.2499L1.5 10.9999L2.25 8.24991M4.25 10.2499L2.25 8.24991"
        stroke={isActive ? hoverColor : "#2E3860"}
      />
    </svg>
  );
};

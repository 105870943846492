import { loadStripe } from "@stripe/stripe-js";
import CONFIG from "config/config";
import { useMemo } from "react";

export const useCountryStripe = (countryCode) => {
  return useMemo(() => {
    switch (countryCode) {
      case "US":
      case "CA":
        return loadStripe(CONFIG.STRIPE.PUBLIC_KEY_US);
      case "AU":
        return loadStripe(CONFIG.STRIPE.PUBLIC_KEY_AU);
      case "UK":
        return loadStripe(CONFIG.STRIPE.PUBLIC_KEY_UK);
      case "FR":
        return loadStripe(CONFIG.STRIPE.PUBLIC_KEY_FR);
      case "BE":
        return loadStripe(CONFIG.STRIPE.PUBLIC_KEY_BE);
      case "NZ":
        return loadStripe(CONFIG.STRIPE.PUBLIC_KEY_NZ);
      case "NL":
        return loadStripe(CONFIG.STRIPE.PUBLIC_KEY_NL);
      case "ES":
        return loadStripe(CONFIG.STRIPE.PUBLIC_KEY_ES);
      case "DE":
        return loadStripe(CONFIG.STRIPE.PUBLIC_KEY_DE);
      default:
        return loadStripe(CONFIG.STRIPE.PUBLIC_KEY_US);
    }
  }, [countryCode]);
};

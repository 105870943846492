export const styles = {
  selectContainer: {
    width: "100%",
    position: "relative" as const,
  },
  select: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "35px",
    border: "1px solid #E2E2FC",
    borderRadius: "8px",
    padding: "6px 12px",
    fontSize: "1rem",
    outline: "none",
    textOverflow: "ellipses",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: "1.1876em",
    letterSpacing: "0.00938em",
    cursor: "pointer",
  },
  iconsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  optionsContainer: {
    display: "flex",
    flexWrap: "wrap" as const,
    justifyContent: "space-between",
    position: "absolute" as const,
    width: "100%",
    top: "35px",
    left: 0,
    border: "1px solid #E2E2FC",
    borderRadius: "8px",
    padding: "12px 12px 0 12px",
    backgroundColor: "#FFFFFF",
    zIndex: 999,
  },
  currencySigns: {
    color: "#AEAFC7",
    marginRight: "8px",
  },
  scrollable: {
    maxHeight: "calc(30vh - 25px)",
    overflowY: "auto" as const,
  },
};

import { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { IconsProps, Target } from "shared/models";
import { TargetSelector } from "components/TargetSelector/TargetSelector";
import { Add, Edit } from "static/media";
import classes from "./styles.module.css";

type option = {
  label: string;
  selected: boolean;
  value: string;
};

interface MultipleTargetSelectorProps {
  label: string;
  Icon?: React.FC<IconsProps>;
  targets: Target[];
  className?: string;
}

export const MultipleTargetSelector = ({ label, targets, className = "", Icon }: MultipleTargetSelectorProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [openOptions, setOpenOptions] = useState(false);

  const amountOfSelectedOptions = targets.filter((target) => target.options.some((o) => o.selected)).length;

  const handleClickOutside = (event: MouseEvent) => {
    if (!containerRef?.current?.contains(event.target as Node) && !buttonRef?.current?.contains(event.target as Node)) {
      if (openOptions) {
        setOpenOptions(false);
      }
    }
  };

  useEffect(() => {
    if (openOptions) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openOptions]);

  return (
    <div
      className={clsx(classes.container, {
        [className]: !!className,
        [classes.hasOptions]: !!amountOfSelectedOptions,
      })}
    >
      <button
        className={clsx(classes.button)}
        onClick={() => {
          setOpenOptions(!openOptions);
        }}
        ref={buttonRef}
      >
        <div className={classes.flexCenter}>
          {Icon && <Icon />}
          <span className={classes.label}>{label} </span>
        </div>
        <div className={classes.flexCenter}>
          {!!amountOfSelectedOptions && (
            <span className={clsx(classes.amountOfSelectedOptions)}>{amountOfSelectedOptions}</span>
          )}

          <div
            className={clsx(classes.separator, {
              [classes.separatorWidthOptions]: !!amountOfSelectedOptions,
            })}
          />

          {amountOfSelectedOptions ? <Edit /> : <Add />}
        </div>
      </button>
      {openOptions && (
        <div className={clsx(classes.options)} ref={containerRef}>
          {targets.map(({ label, icon, options, onUpdate }, index) => {
            return (
              <TargetSelector
                key={label + index}
                label={label}
                Icon={icon}
                options={options}
                onUpdate={onUpdate}
                isMultiple
                index={index}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

import { callNoAuthorizedGraphQLEndpoint } from "module/graphQL";
import { FlyerBuilderStatesMutationType } from "pages/flyer/builder/drawer/livestorage/type";
import CONFIG from "config/config";

export const addFlyerBuilderStates = (payload: FlyerBuilderStatesMutationType) =>
  callNoAuthorizedGraphQLEndpoint(
    `mutation addFBStatesWithoutAuth(
        $input: AddOrUpdateFlyerBuilderStatesInput
    ) {
        addFBStatesWithoutAuth(
            input: $input,
        ) {
            success
            error
        }
    }`,
    {
      input: {
        ...payload,
      },
    },
    CONFIG.FLYER_API_URL
  );

export const updateFlyerBuilderStates = (payload: FlyerBuilderStatesMutationType) =>
  callNoAuthorizedGraphQLEndpoint(
    `mutation updateFBStatesWithoutAuth(
      $input: AddOrUpdateFlyerBuilderStatesInput
    ) {
        updateFBStatesWithoutAuth(
            input: $input,
        ) {
            success
            error
        }
    }`,
    {
      input: {
        ...payload,
      },
    },
    CONFIG.FLYER_API_URL
  );

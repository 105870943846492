import CONFIG from "config/config";
import { isLocal } from "pages/flyer/upload/utils";
import posthog from "posthog-js";

const initPosthog = (): void => {
  const enablePosthog = typeof posthog.init === "function" && CONFIG.POSTHOG_KEY && CONFIG.POSTHOG_HOST;

  if (enablePosthog) {
    try {
      posthog.init(CONFIG.POSTHOG_KEY || "", {
        api_host: CONFIG.POSTHOG_HOST,
        disable_web_experiments: !isLocal() ? false : true, // false enables.
      });
    } catch (error) {
      console.log({
        message: "Error while initializing PostHog",
        error,
      });
    }
  }
};

export default initPosthog;

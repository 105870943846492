import { callAuthorizedGraphQLEndpoint } from "module/graphQL";
import { FlyerBuilderStatesType } from "pages/flyer/builder/drawer/livestorage/type";
import CONFIG from "config/config";

type InputDataType = {
  key: string;
  value: string;
};

const normalizeInputData = (arr: InputDataType[]) =>
  arr
    ? arr.map(({ key, value }) => ({
        key,
        value: JSON.parse(value),
      }))
    : [];

export const getFlyerBuilderState = async (key: string) => {
  const query = `
        query ($key: String!) {
            searchFBStatesWithoutAuth (key: $key) {
                shapes {
                  key
                  value
                }
                bindings {
                  key
                  value
                }
                assets {
                  key
                  value
                }
                documentSettings {
                  format
                  orientation
                  isShowGuidelines
                }
            }
        }
    `;

  try {
    const response = (await callAuthorizedGraphQLEndpoint(query, { key }, CONFIG.FLYER_API_URL)) as any;
    const res = await response.json();

    if (res.data) {
      const {
        searchFBStatesWithoutAuth: { shapes, bindings, assets, documentSettings },
      } = res.data;

      return {
        shapes: normalizeInputData(shapes),
        bindings: normalizeInputData(bindings),
        assets: normalizeInputData(assets),
        documentSettings,
      } as FlyerBuilderStatesType;
    } else {
      throw new Error(`GraphQL request failed with status code ${response.status}`);
    }
  } catch (e: any) {
    console.log("got error when search flyer builder states: ", e.message);
  }
};

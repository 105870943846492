import { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { IconsProps } from "shared/models";
import { Add, Edit, Tick, Trash } from "static/media";
import classes from "./styles.module.css";

type option = {
  label: string;
  selected: boolean;
  value: string;
};

interface TargetSelectorProps {
  label: string;
  Icon?: React.FC<IconsProps>;
  options: option[];
  onUpdate: (options: option[], index?: number) => void;
  className?: string;
  isMultiple?: boolean;
  index?: number;
}

export const TargetSelector = ({
  label,
  options,
  onUpdate,
  className = "",
  Icon,
  isMultiple = false,
  index,
}: TargetSelectorProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [tempOptions, setTempOptions] = useState(options);
  const [openOptions, setOpenOptions] = useState(false);

  const amountOfSelectedOptions = options.filter((option) => option.selected).length;
  const hasSelectedTempOptions = tempOptions.some((option) => option.selected);

  const onCancel = () => {
    setOpenOptions(false);
    setTempOptions(options);
  };

  const onClear = () => {
    setTempOptions(options.map((option) => ({ ...option, selected: false })));
  };

  const onOptionClick = (index: number) => {
    setTempOptions(
      tempOptions.map((o, i) => ({
        ...o,
        selected: i === index ? !o.selected : o.selected,
      }))
    );
  };

  const handleUpdate = () => {
    onUpdate(tempOptions, index);
    setOpenOptions(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (!containerRef?.current?.contains(event.target as Node) && !buttonRef?.current?.contains(event.target as Node)) {
      if (openOptions) {
        onCancel();
        setOpenOptions(false);
      }
    }
  };

  useEffect(() => {
    if (openOptions) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openOptions]);

  return (
    <div
      className={clsx(classes.container, {
        [className]: !!className,
        [classes.hasOptions]: !!amountOfSelectedOptions && !isMultiple,
        [classes.isMultiple]: isMultiple,
      })}
    >
      <button
        className={clsx(classes.button, { [classes.isMultiple]: isMultiple })}
        onClick={() => {
          if (openOptions) {
            onCancel();
          }
          setOpenOptions(!openOptions);
        }}
        ref={buttonRef}
      >
        <div className={classes.flexCenter}>
          {Icon && <Icon />}
          <span className={classes.label}>{label} </span>
        </div>
        <div className={classes.flexCenter}>
          {!!amountOfSelectedOptions && (
            <span
              className={clsx(classes.amountOfSelectedOptions, {
                [classes.marginRight]: isMultiple,
              })}
            >
              {amountOfSelectedOptions}
            </span>
          )}
          {!isMultiple && (
            <div
              className={clsx(classes.separator, {
                [classes.separatorWidthOptions]: !!amountOfSelectedOptions,
              })}
            />
          )}
          {!amountOfSelectedOptions ? <Add /> : <Edit />}
        </div>
      </button>
      {openOptions && (
        <div
          className={clsx(classes.options, {
            [classes.multipleOptions]: isMultiple,
          })}
          ref={containerRef}
        >
          <div className={classes.optionsHeader}>
            <div className={classes.optionsTitleContainer}>
              {Icon && <Icon stroke={"#2E3860"} width={"24"} height={"24"} />}
              <span className={classes.optionsTitle}>{label}</span>
            </div>
            {hasSelectedTempOptions && (
              <button onClick={onClear} className={classes.clear}>
                <Trash /> Clear
              </button>
            )}
          </div>
          <div className={classes.optionsContainer}>
            {tempOptions.map(({ label, selected }, index) => {
              return (
                <div key={label + index} className={classes.option} onClick={() => onOptionClick(index)}>
                  <div className={classes.tickContainer}>
                    {selected && <Tick stroke="#000000" width={"16"} height={"16"} />}
                  </div>
                  <span className={classes.optionLabel}>{label}</span>
                </div>
              );
            })}
          </div>
          <div className={classes.footer}>
            <button className={classes.cancel} onClick={onCancel}>
              Cancel
            </button>
            <button className={classes.update} onClick={handleUpdate}>
              Update
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { DMDocumentNames, DMDocumentFormats } from "shared/constants";
import cn from "classnames";
import { flyerTypesOptions, FlyerTypeOption } from "./flyerTypeOptions";
import { FlyerTypeCard } from "./FlyerTypeCard";
import { styles } from "./styles";

const useStyles = makeStyles(() => styles);

interface FlyerTypeSelectProps {
  handleChangeFlyerType: (newFlyerType: keyof typeof DMDocumentFormats) => void;
  flyerType: keyof typeof DMDocumentFormats;
  scrollable?: boolean;
}

export const FlyerTypeSelect = ({ handleChangeFlyerType, flyerType, scrollable = false }: FlyerTypeSelectProps) => {
  const [open, setOpen] = useState<boolean>();
  const classes = useStyles();
  const currentFlyerTypeOption = flyerTypesOptions.find((f) => f.type === flyerType);

  const openOptions = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.selectContainer}>
      <button className={classes.select} onClick={openOptions}>
        <span>{DMDocumentNames[flyerType]}</span>
        <div className={classes.iconsContainer}>
          <span className={classes.currencySigns}>{currentFlyerTypeOption?.worth}</span>
          {open ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
        </div>
      </button>
      {open && (
        <div
          className={cn(classes.optionsContainer, {
            [classes.scrollable]: scrollable,
          })}
        >
          {flyerTypesOptions.map((flyerTypeOption: FlyerTypeOption) => {
            return (
              <FlyerTypeCard
                key={flyerTypeOption.label}
                flyerTypeData={flyerTypeOption}
                selectedFlyerType={flyerType}
                onClick={() => {
                  handleChangeFlyerType(flyerTypeOption.type as keyof typeof DMDocumentFormats);
                  setOpen(false);
                }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

import { TargetSelector } from "components/TargetSelector/TargetSelector";
import { MultipleTargetSelector } from "components/MultipleTargetSelector/MultipleTargetSelector";
import { Target } from "shared/models";
import styles from "./TargetSelectorArea.module.css";

interface TargetSelectorAreaProps {
  selectorsOptions: Target[];
  nonSelectedOptions?: Target[];
  multiple?: boolean;
}

export const TargetSelectorArea = ({
  selectorsOptions,
  multiple = false,
  nonSelectedOptions,
}: TargetSelectorAreaProps) => {
  return (
    <div className={styles.container}>
      {selectorsOptions.map(({ label, options, icon, onUpdate }, index) => (
        <TargetSelector key={label + index} label={label} Icon={icon} options={options} onUpdate={onUpdate} />
      ))}
      {multiple && <MultipleTargetSelector label="More" targets={nonSelectedOptions!} />}
    </div>
  );
};

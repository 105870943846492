export const styles = {
  statisticsBlockContainer: {
    position: "absolute",
    borderRadius: "8px",
    background: "#FFFFFF",
    padding: "8px 18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    pointerEvents: "none",
    zIndex: 9999,
    boxShadow: "0px 2px 6px 0px #27375414",
  },
  statisticsHeaderWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    fontWeight: 700,
    fontFamily: "'Proxima Nova', 'sans-serif'",
    fontSize: "18px",
    marginBottom: "5px",
  },
  areaStatistics: {
    display: "flex",
    alignItems: "center",
  },
  areaStatisticsTitle: {
    marginLeft: "8px",
  },
  areaStatisticsRoutesContainer: {
    height: "32px",
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
    marginTop: "5px",
    borderTop: "1px solid #E2E2FC",
  },
  areaStatisticsRoutes: {
    display: "flex",
  },
  areaStatisticsRoutesLabel: {
    fontWeight: 700,
    marginRight: "5px",
  },
  statisticsRow: {
    display: "flex",
    fontFamily: "'Proxima Nova', 'sans-serif'",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  statisticsSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  statisticsAmount: {
    fontSize: "14px",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: "3px",
    },
  },
  statisticsLabel: {
    fontSize: "12px",
    color: "#0C172C",
  },
  satisticsNotTargeted: {
    color: "#AEAFC7",
  },
  separator: {
    width: "100%",
    height: "1px",
    background: "#AEAFC7",
    marginBottom: "5px",
  },
  verticalSeparator: {
    width: "1px",
    height: "25px",
    background: "#E2E2FC",
    margin: "0 16px 0px 12px",
  },
  cost: { marginLeft: "3px" },
  topTooltipArrow: { position: "absolute", top: -14, color: "white" },
  bottomTooltipArrow: { position: "absolute", bottom: -14, color: "white" },
  rightTooltipArrow: { position: "absolute", right: -14, color: "white" },
  leftTooltipArrow: { position: "absolute", left: -14, color: "white" },
};

export const RouteIcon = ({ stroke, ...restProps }) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <g clipPath="url(#clip0_3787_81221)">
        <path
          d="M5.25 6.98201V2.91699C5.25 1.95049 6.0335 1.16699 7 1.16699C7.9665 1.16699 8.75 1.95049 8.75 2.91699V3.50199"
          stroke={stroke || "#4F5B94"}
          strokeLinecap="round"
        />
        <path
          d="M8.75 7.00098V11.0833C8.75 12.0498 7.9665 12.8333 7 12.8333C6.0335 12.8333 5.25 12.0498 5.25 11.0833V10.4912"
          stroke={stroke || "#4F5B94"}
          strokeLinecap="round"
        />
        <path
          d="M6.99984 8.75H2.91188C1.94793 8.75 1.1665 7.9665 1.1665 7C1.1665 6.0335 1.94793 5.25 2.91188 5.25H3.49651"
          stroke={stroke || "#4F5B94"}
          strokeLinecap="round"
        />
        <path
          d="M7 5.25H11.0801C12.0484 5.25 12.8333 6.0335 12.8333 7C12.8333 7.9665 12.0484 8.75 11.0801 8.75H10.5193"
          stroke={stroke || "#4F5B94"}
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3787_81221">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

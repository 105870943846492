export const FaqIcon = ({ fill, ...restProps }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11ZM9.62529 13.2822V13.4309H11.692V13.2822C11.6961 12.878 11.7479 12.5386 11.8472 12.2641C11.9506 11.9857 12.1078 11.7436 12.3189 11.5377C12.534 11.3317 12.8092 11.1335 13.1443 10.9428C13.525 10.7331 13.8539 10.4948 14.1311 10.2278C14.4083 9.96092 14.6214 9.65586 14.7704 9.31268C14.9235 8.96949 15 8.58055 15 8.14585C15 7.50143 14.8304 6.94471 14.4911 6.47569C14.1559 6.00286 13.6863 5.6387 13.0822 5.38322C12.4823 5.12774 11.7872 5 10.9969 5C10.2728 5 9.61081 5.12202 9.01086 5.36606C8.41505 5.6101 7.93509 5.97617 7.57099 6.46425C7.41735 6.67256 7.29462 6.90241 7.20279 7.15378C6.98626 7.7465 7.52131 8.28313 8.15234 8.28313H8.26505C8.79521 8.28313 9.18256 7.82492 9.49496 7.39657C9.66874 7.16015 9.88596 6.98475 10.1466 6.87035C10.4114 6.75214 10.6907 6.69304 10.9845 6.69304C11.3031 6.69304 11.5906 6.75405 11.8472 6.87607C12.1078 6.99809 12.3147 7.16969 12.4678 7.39085C12.6209 7.61201 12.6974 7.87321 12.6974 8.17445C12.6974 8.44137 12.6395 8.68351 12.5237 8.90086C12.4078 9.1144 12.2485 9.30887 12.0458 9.48427C11.8472 9.65586 11.6217 9.81411 11.3693 9.95901C11.001 10.1687 10.6886 10.3994 10.4321 10.6511C10.1756 10.899 9.97698 11.2269 9.83631 11.6349C9.69977 12.0429 9.62943 12.592 9.62529 13.2822ZM9.74321 16.6339C10.0122 16.878 10.3328 17 10.7052 17C10.9535 17 11.1789 16.9447 11.3817 16.8341C11.5886 16.7197 11.7541 16.5672 11.8782 16.3765C12.0065 16.1859 12.0706 15.9743 12.0706 15.7417C12.0706 15.3985 11.9341 15.1049 11.661 14.8608C11.392 14.6168 11.0734 14.4948 10.7052 14.4948C10.3328 14.4948 10.0122 14.6168 9.74321 14.8608C9.47427 15.1049 9.3398 15.3985 9.3398 15.7417C9.3398 16.0925 9.47427 16.3899 9.74321 16.6339Z"
        fill={fill || "#011533"}
      />
    </svg>
  );
};

export const StarIcon = ({ stroke, ...restProps }: { stroke?: string | null }) => {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path
        d="M6.99973 0.666748L8.73265 4.17744L12.6079 4.74386L9.8038 7.47502L10.4656 11.3334L6.99973 9.51077L3.53391 11.3334L4.19567 7.47502L1.3916 4.74386L5.26682 4.17744L6.99973 0.666748Z"
        stroke={stroke || "#2E3860"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import React from "react";
import { getSignedUrl } from "graphQL/query/file";

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function useMultiplayerAssets(roomId: string) {
  const onAssetCreate = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (file: File, id: string): Promise<string | false> => {
      const filename = encodeURIComponent(file.name).replace(/[^a-zA-Z0-9 ]/g, "");
      const encodedRoomId = encodeURIComponent(roomId).replace(/[^a-zA-Z0-9 ]/g, "");
      const key = "campaign-builder/" + `/fb-images/${encodedRoomId}/${filename + "id" + new Date().getTime()}`;

      try {
        const signedUrlPUT = await getSignedUrl("oppizi-uploads", key, "PUT", "ap-southeast-2");

        // Upload to S3 using signed URL
        await fetch(signedUrlPUT, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
          },
        });

        const signedUrlGET = await getSignedUrl("oppizi-uploads", key, "GET", "ap-southeast-2");

        return signedUrlGET;
      } catch (err) {
        console.error("errror: ", err);
        throw err;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onAssetDelete = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (id: string): Promise<boolean> => {
      // noop
      return true;
    },
    []
  );

  return { onAssetCreate, onAssetDelete };
}

import { gql } from "@apollo/client";

export const GetSignedUrl = gql`
  query getSignedUrl(
    $bucket: String!
    $key: String!
    $operation: String!
    $region: String
  ) {
    getSignedUrl(
      bucket: $bucket
      key: $key
      operation: $operation
      region: $region
    )
  }
`;

export const styles = {
  eddmSummaryContainer: {
    position: "absolute" as const,
    top: "5px",
    right: "20px",
    width: "360px",
    minHeight: "281px",
    maxHeight: "98vh",
    border: "1px solid #F2F2F6",
    backgroundColor: "white",
    zIndex: 9999,
    display: "flex",
    flexDirection: "column" as const,
    padding: "16px 32px 8px",
    borderRadius: "8px",
    justifyContent: "space-between",
  },
  eddmSummarySection: {
    width: "100%",
    display: "flex",
    flexDirection: "column" as const,
  },
  flexCenterSpacebetween: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  postcardFormat: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#707087",
  },
  flexCenterStart: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  flyerType: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4px 12px",
    cursor: "pointer",
    position: "relative" as const,
    overflow: "hidden",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "#E2E2FC",
      transform: "translateX(100%)",
      transition: "transform 0.4s ease-out",
      zIndex: 0,
    },
    "&:hover::before": {
      transform: "translateX(0)",
    },
    "& span, & svg": {
      marginLeft: "10px",
      position: "relative",
      zIndex: 1,
    },
  },
  locationsWrapper: {
    width: "103%",
    overflow: "scroll",
    maxHeight: "70vh",
    overflowX: "hidden" as const,
  },
  bold18px: {
    fontSize: "18px",
    fontWeight: 700,
    color: "#0C172C",
  },
  bold14px: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#2E3860",
  },
  columnStart: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "flex-start",
  },
  columnEnd: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "flex-end",
  },
  continueButton: {
    width: "100%",
    height: "45px",
    borderRadius: "8px",
    padding: "10px 8px",
    fontSize: "16px",
    fontWeight: 700,
    marginTop: "5px",
    backgroundColor: "#EE4360",
    color: "white",
    cursor: "pointer",
  },
  marginTop12: {
    marginTop: "12px",
  },
  buttonDisabled: {
    backgroundColor: "#E4E4EA",
    color: "#C2C5DD",
    cursor: "default",
  },
};

import { TargetPayload } from "shared/models";
import { TargetSelectorArea } from "components/audience-map/components/TargetSelectorsArea/TargetSelectorsArea";
import { useTargetSelector } from "components/audience-map/components/hooks";
import { SuggestionStart } from "static/media";
import { SuggestedRoute } from "module/eddm/dto";
import cn from "classnames";
import styles from "./styles.module.css";

interface AudienceAttributesProps {
  suggestionsEnabled: boolean | null;
  selectedTargets: TargetPayload[];
  updateSelectedTargets: (targets: TargetPayload[]) => void;
  onSubmit: () => void;
  setShouldAutoSave: (shouldAutoSave: boolean) => void;
  suggestedRoutes: SuggestedRoute[];
}

export const AudienceAttributes = ({
  suggestionsEnabled,
  selectedTargets,
  updateSelectedTargets,
  onSubmit,
  setShouldAutoSave,
  suggestedRoutes,
}: AudienceAttributesProps) => {
  const { limitedSelectorsOptions, nonSelectedOptions } = useTargetSelector({
    selectedTargets,
    updateSelectedTargets,
    suggestionsEnabled,
    fullTargets: false,
    setShouldAutoSave,
  });

  return (
    <div
      className={cn(styles.container, {
        [styles.noBottomBorder]: suggestedRoutes.length > 0,
      })}
    >
      <div>
        <p className={styles.title}>Audience attributes</p>
        <TargetSelectorArea
          selectorsOptions={limitedSelectorsOptions}
          multiple
          nonSelectedOptions={nonSelectedOptions}
        />
      </div>
      {!suggestionsEnabled && !!selectedTargets.length && (
        <button className={styles.buttonSuggestion} onClick={onSubmit}>
          Suggest routes
          <SuggestionStart stroke="#ee4360" style={{ marginLeft: "5px" }} />
        </button>
      )}
    </div>
  );
};

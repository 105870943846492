import posthog from "posthog-js";
import { createContext, useEffect, useState } from "react";

interface FeatureFlagsObj {
  [key: string]: boolean;
}

const defaultValues: { featureFlags: FeatureFlagsObj; loading: boolean } = { featureFlags: {}, loading: true };

export const FeatureFlagContext = createContext(defaultValues);

export const FeatureFlagContextProvider = ({ children }: { children: React.ReactNode }) => {
  //ADD FLAGS WHEN NEEDED
  const _featureFlags = {
    isSkipEnabled: "enable-skip-design",
    isDemographicsEnabled: "enable_demographics",
  };

  const arrayOfFlags = Object.entries(_featureFlags);
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagsObj>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkFeatureFlags = () => {
      try {
        if (!posthog) {
          console.warn("PostHog is not initialized");
          const disabledFlags = arrayOfFlags.reduce((acc, [name, flag]) => {
            acc[name] = false;
            return acc;
          }, {} as FeatureFlagsObj);
          setFeatureFlags(disabledFlags);
        } else {
          const actualFlags = arrayOfFlags.reduce((acc, [name, flag]) => {
            const flagValue = posthog.isFeatureEnabled(flag);
            acc[name] = flagValue || false;
            return acc;
          }, {} as FeatureFlagsObj);
          setFeatureFlags(actualFlags);
          setLoading(false);
        }
      } catch (err) {
        console.error("Posthog error:", err);
        const errorFlags = arrayOfFlags.reduce((acc, [name, flag]) => {
          acc[name] = false;
          return acc;
        }, {} as FeatureFlagsObj);
        setFeatureFlags(errorFlags);
      } finally {
        setLoading(false);
      }
    };
    checkFeatureFlags();
  }, []);

  return (
    <FeatureFlagContext.Provider
      value={{
        featureFlags,
        loading,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};

const CONFIG = {
  ENV: process.env.REACT_APP_ENV || "local",
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/platform",
  GRAPHQL_API_BASE_URL: process.env.REACT_APP_GRAPHQL_API_BASE_URL || "http://localhost:5000/graphql",
  PATHFINDER_API_BASE_URL: process.env.REACT_APP_PATHFINDER_API_BASE_URL || "http://localhost:5001/graphql",
  FLYER_API_URL: process.env.REACT_APP_FLYER_API_URL || "http://localhost:4001/dev/graphql",
  PLATFORM_BASE_URL: process.env.REACT_APP_PLATFORM_BASE_URL || "http://localhost:3000",
  PAYMENT_BASE_URL: process.env.REACT_APP_PAYMENT_BASE_URL || "http://localhost:9000",
  CRYPTO_SECRET_KEY: process.env.REACT_APP_CRYPTO_SECRET_KEY,
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
  REACT_APP_HUBSPOT_API_TOKEN: process.env.REACT_APP_HUBSPOT_API_TOKEN,
  USER_ROLE: {
    ADMIN: "ADMIN",
    CITY_MANAGER: "CITY_MANAGER",
    CLIENT_ADMIN: "CLIENT_ADMIN",
    CLIENT: "ROLE_CLIENT",
    DISTRIBUTOR: "DISTRIBUTOR",
    RECRUITER: "RECRUITER",
    SPY: "ROLE_SPY",
  },
  STORE: {
    USER_KEY: "user",
    API_KEY: "apiKey",
  },
  PDF_FLYER_BUCKET_REGION: process.env.REACT_APP_PDF_FLYER_BUCKET_REGION,
  PDF_FLYER_BUCKET: process.env.REACT_APP_PDF_FLYER_BUCKET,
  POSTHOG_KEY: process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
  POSTHOG_HOST: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  INTEGRATIONS_SERVICE_BASE_URL: process.env.REACT_APP_INTEGRATIONS_SERVICE_BASE_URL || "http://localhost:4200/dev",
  TALKJS: {
    appName: process.env.REACT_APP_TALKJS_APP_NAME,
    secretKey: process.env.REACT_APP_TALKJS_SECRET_KEY,
    appID: process.env.REACT_APP_TALKJS_APP_ID,
  },
  STRIPE: {
    PUBLIC_KEY_US: process.env.REACT_APP_PUBLIC_STRIPE_KEY_US,
    PUBLIC_KEY_AU: process.env.REACT_APP_PUBLIC_STRIPE_KEY_AU,
    PUBLIC_KEY_UK: process.env.REACT_APP_PUBLIC_STRIPE_KEY_UK,
    PUBLIC_KEY_FR: process.env.REACT_APP_PUBLIC_STRIPE_KEY_FR,
    PUBLIC_KEY_BE: process.env.REACT_APP_PUBLIC_STRIPE_KEY_BE,
    PUBLIC_KEY_NZ: process.env.REACT_APP_PUBLIC_STRIPE_KEY_NZ,
    PUBLIC_KEY_NL: process.env.REACT_APP_PUBLIC_STRIPE_KEY_NL,
    PUBLIC_KEY_DE: process.env.REACT_APP_PUBLIC_STRIPE_KEY_DE,
    PUBLIC_KEY_ES: process.env.REACT_APP_PUBLIC_STRIPE_KEY_ES,
  },
};

export default CONFIG;

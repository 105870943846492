import React from "react";

export const Checked: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.96875" y="0.220703" width="18" height="18" rx="9" fill="#E2E2FC" />
      <rect x="3.96875" y="3.2207" width="12" height="12" rx="6" fill="#E2E2FC" />
      <path
        d="M5.96875 9.7207L8.46875 12.2207L13.9688 6.7207"
        stroke="#4F5B94"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import { useState } from "react";
import cn from "classnames";
import { useStore } from "store";
import { makeStyles, CircularProgress } from "@material-ui/core";
import { VisiblePostCodeType, StoreType } from "store/types";
import { DMDocumentFormats } from "shared/constants";
import { LocationLatLngType } from "components/place-autocomplete/type";
import { PlaceAutocomplete } from "components/place-autocomplete";
import { PossibleFlyerType } from "pages/flyer/builder/drawer";
import { FlyerTypeSelect } from "components/FlyerTypeSelect";
import { updateCampaignExtraData } from "graphQL/mutations/campaignExtraData";
import { styles } from "./styles";

const useStyles = makeStyles(() => styles);

interface EDDMEmptyStateProps {
  countryCode: string;
  disablePlaceAutocomplete: boolean;
  handleAutocompleteChanged: (newMarker: any) => void;
  getFilteredPostCodes?: (key: string) => VisiblePostCodeType[];
}

export const EDDMEmptyState: React.FC<EDDMEmptyStateProps> = ({
  countryCode,
  disablePlaceAutocomplete,
  handleAutocompleteChanged,
  getFilteredPostCodes,
}) => {
  const {
    campaign: { id: campaignId, flyerType },
    updateCampaign,
  }: StoreType = useStore();
  const [location, setLocation] = useState<LocationLatLngType | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const submitDisabled = !location;

  const onPlaceChanged = (newLocation: LocationLatLngType) => {
    if (!newLocation) return;
    setLocation(newLocation);
  };

  const onSubmit = async () => {
    if (submitDisabled) return;
    setSubmitting(true);
    await updateCampaignExtraData({
      campaignId,
      flyerType,
    });
    handleAutocompleteChanged(location);
    setSubmitting(false);
  };

  const handleChangeFlyerType = (newFlyerType: PossibleFlyerType) => {
    updateCampaign({ flyerType: newFlyerType });
  };

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.choseLocationModal}>
        <span className={classes.title}>Choose locations for your campaign</span>
        <span className={classes.text}>Select delivery routes to reach every doorstep with your campaign</span>
        <span className={classes.label}>
          First location <span className={classes.mandatory}>*</span>
        </span>
        <PlaceAutocomplete
          countryCode={countryCode}
          disabled={disablePlaceAutocomplete}
          placeholder={"Enter a city, neighborhood, or specific address"}
          postCodeSearch={true}
          onPlaceChanged={onPlaceChanged}
          getFilteredPostCodes={getFilteredPostCodes}
          disableAutoClear
        />
        <span className={classes.label}>
          Postcard format <span className={classes.mandatory}>*</span>
        </span>
        <FlyerTypeSelect
          handleChangeFlyerType={handleChangeFlyerType}
          flyerType={flyerType as keyof typeof DMDocumentFormats}
        />
        <button
          disabled={submitDisabled}
          className={cn(classes.button, { [classes.disabled]: submitDisabled })}
          onClick={onSubmit}
        >
          Explore map
          {submitting && <CircularProgress size={15} className={classes.submittingIcon} />}
        </button>
      </div>
    </div>
  );
};

import React, { useState } from "react";
import cn from "classnames";
import { usePostHog } from "posthog-js/react";
import { EmitAnalyticsEvent } from "module/analytics/application/EmitAnalyticsEvent";
import { formatNumber, toFixed2 } from "utils/formators";
import { SelectedRoute } from "module/eddm/dto";
import { makeStyles } from "@material-ui/core";
import { DeleteIcon } from "static/media/delete-icon";
import { ResidencesIcon } from "components/icons/components/ResidencesIcon";
import { useStore } from "store";
import { styles } from "./styles";

const useStyles = makeStyles(() => styles);

interface RouteCardProps {
  route: SelectedRoute;
  className: string;
  isDeleting: boolean;
  handleRouteDelete: (route: SelectedRoute) => void;
}

export const RouteCard = ({ route, className, isDeleting, handleRouteDelete }: RouteCardProps) => {
  const classes = useStyles();
  const {
    user: { id: userId },
    client: { name: clientName },
    campaign: { isSubmitted },
    costsCalculationData: { costPerFlyer },
  } = useStore();
  const posthog = usePostHog();
  const posthogEventTracker = new EmitAnalyticsEvent(posthog);

  const totalAddresses = route?.metadata ? route?.metadata?.HOME_COUNT + route?.metadata?.APT_COUNT : 0;

  return (
    <div
      className={cn(classes.routeCardContainer, className, {
        [classes.deleting]: isDeleting,
      })}
    >
      <div className={classes.routeHeader}>
        <div className={cn(classes.routeTitle)}>
          <span className={classes.name}>
            {route.metadata.ZIP} - {route.metadata.CROUTE}
          </span>
        </div>
        <button className={classes.iconButton}>
          <DeleteIcon
            disabled={isSubmitted}
            width={"16"}
            height={"16"}
            onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
              e.preventDefault();
              e.stopPropagation();
              if (isSubmitted) return;
              posthogEventTracker.run({
                eventName: `Deleted single route`,
                userId: userId,
                clientName,
                device: navigator.userAgent,
              });
              handleRouteDelete(route);
            }}
          />
        </button>
      </div>
      <div className={classes.routeStatistics}>
        <div className={classes.residences}>
          <ResidencesIcon />
          <span>{formatNumber(totalAddresses)}</span>
        </div>
        <div className={classes.costContainer}>
          <span className={classes.statisticLabel}>Cost</span>
          <span className={classes.cost}>${formatNumber(toFixed2(costPerFlyer * totalAddresses))}</span>
        </div>
      </div>
    </div>
  );
};

import { useEffect, useState, useRef, useContext, useCallback } from "react";
import { useHistory } from "react-router";
import { useReactToPrint } from "react-to-print";
import { generatePath } from "react-router-dom";
import { usePostHog } from "posthog-js/react";

import { SnackBarContext } from "components/snack-bar";
import Box from "@material-ui/core/Box";
import { COUPON_CODE_ERROR_MESSAGE, CAMPAIGN_INTERNAL_STATUSES } from "pages/constants";
import { ProgressBarContext } from "components/progress-bar";
import { decrypt } from "pages/auth/utils";
import { getEarliestStartDate } from "components/details-form/utils";
import CheckoutContainer from "pages/campaign/checkout/container";

import { addDays as addDaysWithTimezone, addBusinessDays } from "components/date-picker/utils/controlDate";
import { getCurrencyCode, gtagWrapper } from "utils";
import { useGetFlyerInfo } from "pages/flyer/flyerTypePage/useGetFlyerInfo";
import ROUTES from "../../../../config/routing";
import QuoteView from "../component/QuoteView";
import { formatDate } from "../../../../utils/date";
import { calculateDates, convertNumber, createPrintingPage } from "./utils";
import BounceLoader from "../../../../components/loaders/bounce-loader";
import { apiClient } from "../../../../module/api";
import { CAMPAIGN_ENUM_TYPES, CAMPAIGN_LOG_ENUM_TYPES } from "../../../../shared/constants";
import useExitPrompt from "../../../../Hooks/useExitPrompt";
import {
  updateCampaignExtraData,
  updateSelfServeCampaignData,
  updateCampaign as updateCampaignDB,
  updateCampaignCityMetrics,
  getCampaignFlyerData,
  getCampaignCost,
} from "../../../../graphQL";
import useCalculating from "../../../../Hooks/useCalculating";
import { calculateTaxAndTotalCost } from "../../../../Hooks/useCalculating/utils";
import { useStore } from "../../../../store";
import { insertCampaignLogs } from "../../../../Logs/campaign/gql";
import { replaceNullValue } from "../../../../Logs/campaign/utils";
import { toFixed2 } from "../../../../utils/formators";

const QuoteContainer = () => {
  const {
    city,
    country,
    costsCalculationData,
    client,
    user,
    timezone,
    campaign: {
      id: campaignId,
      isD2D,
      submittedBy,
      quote,
      stripeCouponCode,
      isPaymentSkipped,
      startDate: savedStartDate = null,
      updatedAt,
      flyerWeight,
      flyerType,
      campaignName,
      campaignDuration,
      channel,
      internalStatus,
    },
    updateCampaign,
    updateCostsCalculationData,
  } = useStore();
  const posthog = usePostHog();

  useCalculating();

  const [firstPageRef, secondPageRef] = [useRef(), useRef()];
  const history = useHistory();

  useExitPrompt(false);

  const [{ startDate, endDate }, setDates] = useState({ startDate: {}, endDate: {} });
  const [data, setData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitConfirmationModalOpen, setIsSubmitConfirmationModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gtagEventSent, setGtagEventSent] = useState(false);
  const [couponErrorMessage, setCouponErrorMessage] = useState("");
  const [showCheckout, setShowCheckout] = useState(false);

  const promotionIdRef = useRef("");
  const customerIdRef = useRef("");

  const runSnackBar = useContext(SnackBarContext);
  const runProgressBar = useContext(ProgressBarContext);
  const { pageState, isLoading: isLoadingFlyerInfo, flyerInfo } = useGetFlyerInfo();
  const { detailedCost, settings } = costsCalculationData;
  const { distribution, permit, printing, travel, totalCost, outfit, tax, subtotal, amountOff, percentOff } =
    detailedCost;

  const { countryTaxRate } = settings;

  const clientId = client.id;
  const userId = user.id;

  let campaignDetailedCosts = {
    distribution,
    permit,
    printing,
    travel,
    outfit,
    bonus: {
      quantity: 0,
      rate: 0,
      total: 0,
    },
    subtotal,
    tax,
    totalCost,
    amountOff,
    percentOff,
  };
  const currencySign = country?.currencySign || "$";

  const minStartDate = addBusinessDays(new Date(), country.countryDefaultSettings.defaultCampaignLimits, timezone);
  const maxEndDate = addDaysWithTimezone(new Date(), 60, timezone);

  const runSideEffect = async () => {
    await updatePathfinderData();
    await updateCampaignAndCampaignExtraData();
    if (!isPaymentSkipped) {
      setIsModalOpen(false);
      setShowCheckout(true);
    } else {
      await sideEffectAfterCreatingCityPlansAndMissions({ saveLogs: false });
    }
  };

  useEffect(() => {
    /**
     * when refresh the submit page or reenter to submit page, we fetch the coupon code from the db.
     * but promotionIdRef.current is empty
     * so we should validate coupon code once again before submit campaign.
     * and should update promotion id and customer id to send to stripe
     */
    if (
      isLoaded &&
      internalStatus === CAMPAIGN_INTERNAL_STATUSES.DRAFT &&
      stripeCouponCode &&
      !promotionIdRef.current
    ) {
      handleApplyCouponCode({ stripeCouponCode });
    }
  }, [isLoaded, stripeCouponCode, internalStatus]);

  useEffect(() => {
    /**
     * This is for really rare case.
     * we submit campaign with coupon and don't click pay button on stripe page.
     * i.e. the campaign is still draft status but coupon was already applied
     * then we skipped payment on platform for this campaign and we don't show the coupon code
     * so we need to remove coupon effect
     */
    if (isPaymentSkipped && stripeCouponCode && internalStatus === CAMPAIGN_INTERNAL_STATUSES.DRAFT) {
      updateCouponCode({ stripeCouponCode: null, amountOff: null, percentOff: null });

      // to do : should we also remove coupon code from mongodb when we skipped payment?
    }
  }, [isPaymentSkipped, stripeCouponCode, internalStatus]);

  useEffect(() => {
    if (history?.location?.search) {
      const decryptedCampaignId = decrypt(history?.location?.search.split("payment=")[1]);
      window.history.replaceState(null, null, `${window.location.href.split("?payment")[0]}`);

      if (decryptedCampaignId === campaignId) {
        return sideEffectAfterCreatingCityPlansAndMissions({ saveLogs: true });
      } else {
        runSnackBar({
          type: "error",
          msg: `Payment error.`,
          vertical: "bottom",
          horizontal: "right",
        });
      }
    }
  }, [history?.location?.search]);

  useEffect(() => {
    setIsLoaded(false);
    //get array of precise locations addresses
    const selectedAddressesArr = !isD2D ? city.addresses : [];

    const dateValue = !isD2D ? getEarliestStartDate(selectedAddressesArr) : savedStartDate;
    const dates = calculateDates({
      value: dateValue,
      minStartDate,
      maxEndDate,
      campaignDuration,
    });
    setDates(dates);

    let newInernalStatus = internalStatus;
    if (history?.location?.search) {
      const decryptedCampaignId = decrypt(history?.location?.search.split("payment=")[1]);
      if (decryptedCampaignId === campaignId) {
        newInernalStatus = CAMPAIGN_INTERNAL_STATUSES.IN_REVIEW;
      }
    }

    const result = {
      submitter: `${user.firstName} ${user.lastName}`,
      submittedBy,
      clientName: user?.clientName || client?.name || "",
      campaignName: campaignName ? campaignName.trim() : campaignName,
      channel,
      campaignDuration,
      flyerType,
      flyerWeight,
      currencySign,
      updatedAt: updatedAt || new Date(),
      internalStatus: newInernalStatus,
      isSubmitted: newInernalStatus !== CAMPAIGN_INTERNAL_STATUSES.DRAFT,
      quote: quote ? [...new Array(5 - String(quote).length).fill("0"), String(quote)].join("") : 0,
      country,
      amountOff,
      percentOff,
      cityName: city.name,
      grand: totalCost,
      couponDiscount: amountOff || 0,
    };
    setData(result);

    setIsLoaded(true);
  }, [quote, stripeCouponCode, amountOff, percentOff, internalStatus, campaignDuration]);

  useEffect(() => {
    if (!gtagEventSent && isModalOpen && startDate) {
      gtagWrapper({
        event: "ss_submit_started",
        client_id: clientId.toString(),
        user_id: userId,
        campaign_id: campaignId,
        launch_date: formatDate(new Date(startDate.value), "MMM d, yyyy"),
        duration: campaignDuration + " weeks",
        city_id: JSON.stringify(city.id),
        nr_of_areas: distribution.quantity,
        nr_of_missions: distribution.quantity,
        nr_of_flyers: printing.quantity,
        flyer_type: flyerType,
        flyer_weight: flyerWeight,
        total_cost: convertNumber({
          number: data.grand,
          currency: currencySign,
          fixed: 2,
        }),
      });
      setGtagEventSent(true);
    }
    if (posthog && isModalOpen) {
      posthog.capture("Viewed Submission Popup", {
        event_category: "Quote",
        campaign_distribution_cost: distribution.total,
        campaign_travel_cost: travel.total,
        campaign_permit_cost: permit.total,
        campaign_printing_cost: printing.total,
        campaign_grand_total: data.grand,
        campaign_quote_id: quote,
      });
    }
  }, [isModalOpen]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsChecked(false);
    setLoading(false);
  };

  const getFlyerInfo = async () => {
    const data = await getCampaignFlyerData(campaignId);
    window.open(data?.campaignFlyerInfo?.s3Link || "", "_blank").focus();
  };

  const errorOnCreateCityPlansAndMissions = (error) => {
    runProgressBar(0);
    runSnackBar({
      type: "error",
      msg: `Server error failed to submit company. ${error.message}`,
      vertical: "bottom",
      horizontal: "right",
    });

    handleCloseModal();
  };

  const headerActions = {
    BACK: {
      action: async () => {
        if (internalStatus === CAMPAIGN_INTERNAL_STATUSES.DRAFT || !internalStatus) {
          history.push(generatePath(ROUTES.CAMPAIGN_DETAILS, { campaignId, clientId }));
        } else {
          history.push(generatePath(ROUTES.CAMPAIGN_AUDIENCE, { campaignId, clientId }));
        }
      },
    },
  };

  const handleDatesChange = () =>
    isD2D
      ? history.push(generatePath(ROUTES.CAMPAIGN_DETAILS, { campaignId, clientId }))
      : history.push(generatePath(ROUTES.CAMPAIGN_AUDIENCE, { campaignId, clientId }));

  const handlePrint = useReactToPrint({
    content: () => {
      const print = document.createElement("div");

      if (firstPageRef.current) {
        const page = createPrintingPage(firstPageRef);
        print.appendChild(page);
      }

      if (secondPageRef.current) {
        const page = createPrintingPage(secondPageRef);
        print.appendChild(page);
      }

      return print;
    },
  });

  const sideEffectAfterCreatingCityPlansAndMissions = async ({ saveLogs = false }) => {
    await updateCampaignExtraData({
      campaignId,
      paymentStatus: "uncaptured",
      internalStatus: CAMPAIGN_INTERNAL_STATUSES.IN_REVIEW,
      lastActiveStep: "submit",
    });
    updateCampaign({
      internalStatus: CAMPAIGN_INTERNAL_STATUSES.IN_REVIEW,
      isSubmitted: true,
      lastActiveStep: "submit",
    });
    runProgressBar(0);
    setIsSubmitConfirmationModalOpen(true);
    const promotionId = promotionIdRef.current || "";
    localStorage.setItem(`first_time_transaction_${promotionId}`, promotionId);
    savedStartDate &&
      gtagWrapper({
        event: "ss_submit_completed",
        client_id: clientId,
        user_id: userId,
        campaign_id: campaignId,
        launch_date: savedStartDate ? formatDate(new Date(savedStartDate), "MMM d, yyyy") : "No date available",
        duration: campaignDuration + " weeks",
        city_id: city.id.toString(),
        nr_of_areas: distribution.quantity,
        nr_of_missions: distribution.quantity,
        nr_of_flyers: printing.quantity,
        flyer_type: flyerType,
        flyer_weight: flyerWeight,
        currency_code: getCurrencyCode(country),
        total_cost: convertNumber({
          number: data.grand,
          currency: "",
          fixed: 2,
        }),
        firstname: user.firstName,
        lastname: user.lastName,
        phone: user.phone,
        email: user.email,
      });
    if (saveLogs) {
      await insertCampaignLogs([
        {
          campaignId,
          type: CAMPAIGN_LOG_ENUM_TYPES.CAMPAIGN_SUBMITTED,
          additionalInfo: `[CB] CAMPAIGN EDIT Subtotal: ${replaceNullValue(
            subtotal.toFixed(2)
          )}, Amount off: ${replaceNullValue(amountOff)}, Percent off: ${replaceNullValue(
            percentOff
          )}, Total: ${replaceNullValue(Number(totalCost.toFixed(2)))}`,
        },
        {
          campaignId,
          type: CAMPAIGN_LOG_ENUM_TYPES.SET_PAYMENT_STATUS,
          additionalInfo: `From campaign builder. Status: [uncaptured]`,
        },
      ]);

      await updateDetailedCosts();
    }
  };

  const updateDetailedCosts = async () => {
    // Gets for DM and D2D/H2H (endpoint used for both)
    const detailedCost = await getCampaignCost({ id: campaignId, cityId: city ? city.id : 0 });
    updateCostsCalculationData({
      detailedCost,
    });
  };

  const updatePathfinderData = async () => {
    await updateSelfServeCampaignData({
      campaignId: campaignId,
      campaignDetailedCosts,
      selectedCities: [city].reduce((acc, city) => {
        if (!city.id) return acc;
        const { addresses, id, isVisible, lat, lng, name, nameWithState, isAddressesPopupOpened, postalCode } = city;
        return [
          ...acc,
          {
            addresses: addresses.filter((address) => address.id),
            id,
            isVisible,
            lat,
            lng,
            name,
            nameWithState,
            isAddressesPopupOpened,
            baPerLocation: !isD2D ? country.baPerLocation : null,
            postalCode,
          },
        ];
      }, []),
    });
  };

  const updateCampaignAndCampaignExtraData = async () => {
    const citiesMetrics = [
      {
        id: `newCity_${1}`,
        cityId: Number(city.id),
        totalFlyers: printing.quantity,
        totalMissions: distribution.quantity,
        distributionCost: distribution.total,
        travelCost: travel.total,
        permitCost: permit.total,
        printingCost: printing.total,
        outfitCost: outfit.total,
        //DM Campaign
        postingCost: null,
        dataCost: null,
        bonusBudget: null,
        freightCost: null,
      },
    ];

    await updateCampaignCityMetrics({
      campaignId: campaignId,
      data: citiesMetrics,
    });

    await updateCampaignDB({
      campaignId,
      campaignDurationWeek: campaignDuration,
      // offerExpirationDate: new Date(`${endDate.value}`),
      name: campaignName.trim(),
      printingCostPerFlyer: printing.rate,
      //CONVERT TO CENTS
      distributionCost: distribution.rate * 100,
    });

    await updateCampaignExtraData({
      campaignId,
      stripeCouponCode,
      startDate: new Date(`${startDate.value}`),
      // offerExpiryDate: new Date(`${endDate.value}`),
      flyerType,
      flyerWeight,
      campaignDuration,
      totalCosts: totalCost,
      taxes: tax,
      submitterId: user.id,
      flyersCount: printing.quantity,
      baPerLocation: !isD2D ? country.baPerLocation : null,
    });
  };

  const handleH2HSubmit = async () => {
    try {
      if (stripeCouponCode) {
        if (!(await handleApplyCouponCode({ stripeCouponCode }))) {
          throw new Error("Sorry, this coupon code is not active any more.");
        }
      }

      runProgressBar(60);

      runProgressBar(70);

      await runSideEffect();
    } catch (error) {
      errorOnCreateCityPlansAndMissions(error);
    }
  };

  const handleD2DSubmit = async () => {
    try {
      if (stripeCouponCode) {
        if (!(await handleApplyCouponCode({ stripeCouponCode }))) {
          throw new Error("Sorry, this coupon code is not active any more.");
        }
      }

      runProgressBar(70);

      await runSideEffect();
    } catch (error) {
      errorOnCreateCityPlansAndMissions(error);
    }
  };

  const getGrandForStripe = () => {
    let grand = totalCost + amountOff;
    if (percentOff && percentOff < 100) {
      /**
       * on stripe side in case we applied percent off coupon
       * grand - grand * percentOff / 100 = totalCost
       */
      grand = (totalCost * 100) / (100 - percentOff);
    }

    return Math.trunc((Number(grand) * 100).toFixed(2));
  };

  const fetchStripeCheckout = async () => {
    const promotionId = promotionIdRef.current || "";
    const res = await apiClient.embeddedCheckoutCampaign({
      campaignId,
      clientId: clientId,
      campaignName: `Campaign ${campaignName ? campaignName.trim() : campaignName}`,
      grand: getGrandForStripe(),
      currencyCode: getCurrencyCode(country),
      countryCode: country?.code,
      promotionId,
      customerId: customerIdRef.current || "",
      userId,
      campaignDetailedCosts,
    });
    if (res.status === 200) {
      const { clientSecret, paymentIntent, paymentStatus } = await res.json();
      await updateCampaignExtraData({
        campaignId,
        paymentIntent,
        paymentStatus,
      });

      return clientSecret;
    }
  };

  const onSubmit = async () => {
    runProgressBar(40);
    setLoading(true);

    gtagWrapper({
      event: "ss_submit_tc_agreed",
      client_id: clientId,
      user_id: userId,
      campaign_id: campaignId,
      launch_date: formatDate(new Date(startDate.value), "MMM d, yyyy"),
      duration: campaignDuration + " weeks",
      city_id: JSON.stringify([city.id]),
      nr_of_areas: distribution.quantity,
      nr_of_missions: distribution.quantity,
      nr_of_flyers: printing.quantity,
      flyer_type: flyerType,
      flyer_weight: flyerWeight,
      total_cost: convertNumber({
        number: data.grand,
        currency: currencySign,
        fixed: 2,
      }),
    });

    if (channel === CAMPAIGN_ENUM_TYPES.HANDTOHAND) {
      await handleH2HSubmit();
    }

    if (channel === CAMPAIGN_ENUM_TYPES.LETTERBOX) {
      await handleD2DSubmit();
    }
    setLoading(false);
  };

  const updateCouponCode = async ({ stripeCouponCode, amountOff, percentOff }) => {
    const { tax, totalCost } = calculateTaxAndTotalCost({ countryTaxRate, subtotal, amountOff });
    campaignDetailedCosts = {
      ...campaignDetailedCosts,
      amountOff,
      percentOff,
      tax,
      totalCost,
    };

    updateCampaign({ stripeCouponCode });
    updateCostsCalculationData({
      detailedCost: {
        ...detailedCost,
        tax: toFixed2(tax),
        totalCost: toFixed2(totalCost),
        amountOff,
        percentOff,
      },
    });
  };

  const getCustomerEmail = async (customer) => {
    try {
      const res = await apiClient.getCustomer({
        id: customer,
        countryCode: country?.code,
      });

      if (res.status === 200) {
        const { customer: responseData } = await res.json();

        if (responseData) {
          return responseData.email;
        }
      }

      return null;
    } catch (err) {
      return null;
    }
  };

  const handleApplyCouponCode = async ({ stripeCouponCode, saveLogs = false }) => {
    if (stripeCouponCode) {
      const code = stripeCouponCode.trim();
      try {
        const res = await apiClient.getPromotionCode({
          promotionCode: code,
          countryCode: country?.code,
        });
        if (res.status !== 200) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["DEFAULT"]);
        }
        const { promotionCodes: responseData } = await res.json();
        if (!responseData) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["DEFAULT"]);
        }

        const { data } = responseData;
        if (!data || !data[0] || code !== data[0].code) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["DEFAULT"]);
        }

        const {
          id: promoId,
          coupon: couponObject,
          max_redemptions: max_redemptions_promotion_code,
          times_redeemed: times_redeemed_promotion_code,
          customer,
          restrictions,
          expires_at,
        } = data[0];
        if (!couponObject && !couponObject.id) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["NO_PROMO_EXIST"]);
        }
        if (
          max_redemptions_promotion_code &&
          times_redeemed_promotion_code &&
          max_redemptions_promotion_code <= times_redeemed_promotion_code
        ) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["EXCEED_USE_PROMO"]);
        }

        const { max_redemptions: max_redemptions_coupon, times_redeemed: times_redeemed_coupon } = couponObject;
        if (max_redemptions_coupon && times_redeemed_coupon && max_redemptions_coupon <= times_redeemed_coupon) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["EXCEED_USE_COUPON"]);
        }

        if (customer) {
          const customerEmail = await getCustomerEmail(customer);

          if (customerEmail !== user.email) {
            throw new Error(COUPON_CODE_ERROR_MESSAGE["NO_PERMISSION"]);
          }
          customerIdRef.current = customer;
        }

        /**
         * if coupon is fixed discount and has different currency
         */
        if (
          couponObject.amount_off > 0 &&
          couponObject.currency &&
          couponObject.currency.toUpperCase() !== getCurrencyCode(country).toUpperCase()
        ) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["INVALID_CURRENCY"]);
        }

        if (couponObject.duration_in_months && couponObject.created) {
          const expiredDate = new Date(Number(couponObject.created) * 1000);
          expiredDate.setMonth(expiredDate.getMonth() + Number(couponObject.duration_in_months));
          if (expiredDate < new Date()) {
            throw new Error(COUPON_CODE_ERROR_MESSAGE["COUPON_EXPRIED"]);
          }
        }

        /**
         * this promo code has some restrictions such as 'minimum_amount'
         */
        if (restrictions) {
          const { minimum_amount, minimum_amount_currency, first_time_transaction } = restrictions;

          if (minimum_amount) {
            if (minimum_amount / 100 > subtotal) {
              throw new Error(COUPON_CODE_ERROR_MESSAGE["MINIMUM_ORDER"]);
            }

            if (
              minimum_amount > 0 &&
              minimum_amount_currency &&
              minimum_amount_currency.toUpperCase() !== getCurrencyCode(country).toUpperCase()
            ) {
              throw new Error(COUPON_CODE_ERROR_MESSAGE["INVALID_CURRENCY_IN_RESTRICTION"]);
            }
          }

          if (first_time_transaction) {
            // TO DO: SAVE THIS ON STRIPE CUSTOMER METADATA OR ON THE OPPIZI USER
            const didReedemed = !!localStorage.getItem(`first_time_transaction_${promoId}`);
            if (didReedemed) {
              throw new Error(COUPON_CODE_ERROR_MESSAGE["ONLY_FIRST"]);
            }
          }
        }

        if (expires_at) {
          if (new Date().getTime() / 1000 > expires_at) {
            throw new Error(COUPON_CODE_ERROR_MESSAGE["PROMO_EXPIRED"]);
          }
        }
        /**
         * if percent_off is a positive float larger than 0, and smaller or equal to 100,
         * that represents the discount the coupon will apply
         *
         * amount_off is a positive integer representing the amount to subtract from an invoice total
         *
         * amount_off = subtotal * percent_off / 100
         */
        let percent_off = null;
        let amount_off = null;
        let flag = false;

        if (couponObject.percent_off > 0 && couponObject.percent_off < 100) {
          percent_off = couponObject.percent_off;
          amount_off = (subtotal * percent_off) / 100;
          amount_off = Math.round((((subtotal * percent_off) / 100) * 100).toFixed(2)) / 100;
          flag = true;
        }
        if (couponObject.amount_off > 0 && couponObject.amount_off < subtotal * 100) {
          amount_off = Math.round(((couponObject.amount_off / 100) * 100).toFixed(2)) / 100;
          flag = true;
        }
        if (!flag) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["INCORRECT_DISCOUNT_VALUE"]);
        }

        if (subtotal - amount_off <= 0.5) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["LESS_THAN"]);
        }

        await updateCouponCode({
          stripeCouponCode: code,
          amountOff: amount_off,
          percentOff: percent_off,
        });

        const snackBarMessage = percent_off
          ? `Your ${percent_off}% discount has been succesfully applied!`
          : `Your ${currencySign}${amount_off} discount has been succesfully applied!`;

        runSnackBar({
          type: "success",
          vertical: "top",
          msg: snackBarMessage,
        });

        if (saveLogs) {
          await insertCampaignLogs([
            {
              campaignId,
              type: CAMPAIGN_LOG_ENUM_TYPES.SET_COUPON_CODE,
              additionalInfo: `[CB] CAMPAIGN EDIT Coupon:${replaceNullValue(code)}, Amount off: ${replaceNullValue(
                amount_off
              )}, Percent off: ${replaceNullValue(percent_off)}`,
            },
          ]);
        }

        promotionIdRef.current = promoId;
        setCouponErrorMessage("");
        return true;
      } catch (err) {
        setCouponErrorMessage(err.message);
      }
    }

    // in case invalid or empty code
    await updateCouponCode({
      stripeCouponCode: null,
      amountOff: null,
      percentOff: null,
    });
    promotionIdRef.current = "";
    customerIdRef.current = "";

    return false;
  };

  return isLoaded && Object.keys(data).length && !isLoadingFlyerInfo ? (
    <QuoteView
      refs={{ firstPageRef, secondPageRef }}
      isPaymentSkipped={isPaymentSkipped}
      stripeCouponCode={stripeCouponCode}
      couponErrorMessage={couponErrorMessage}
      setCouponErrorMessage={setCouponErrorMessage}
      data={data}
      onSubmit={onSubmit}
      handleViewDesignClick={getFlyerInfo}
      dates={{ startDate, endDate }}
      headerActions={headerActions}
      hideBackButton={
        internalStatus !== CAMPAIGN_INTERNAL_STATUSES.DRAFT && internalStatus !== CAMPAIGN_INTERNAL_STATUSES.IN_REVIEW
      }
      timezone={timezone}
      handlePrint={handlePrint}
      toAudience={() => history.push(generatePath(ROUTES.CAMPAIGN_AUDIENCE, { campaignId, clientId }))}
      isChecked={isChecked}
      setIsChecked={setIsChecked}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      isSubmitConfirmationModalOpen={isSubmitConfirmationModalOpen}
      setIsSubmitConfirmationModalOpen={setIsSubmitConfirmationModalOpen}
      handleCloseModal={handleCloseModal}
      loading={loading}
      onApplyCouponCode={handleApplyCouponCode}
      detailedCost={costsCalculationData.detailedCost}
      handleDatesChange={handleDatesChange}
      pageState={pageState}
      flyerInfo={flyerInfo}
      checkoutComponent={() => (
        <CheckoutContainer fetchStripeCheckout={fetchStripeCheckout} countryCode={country.code} />
      )}
      showCheckout={showCheckout}
      onCloseCheckout={() => setShowCheckout(false)}
    />
  ) : (
    <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100vh">
      <BounceLoader />
    </Box>
  );
};

export default QuoteContainer;

import CONFIG from "../../config/config";
import { callAuthorizedGraphQLEndpoint } from "../../module/graphQL";

export const getIntercectedCarrierRoutesWithSuggestionsGQL = (variables) => {
  const gql = `
    query(
      $after: String
      $centerSphere: [Float]
      $radius: Float
      $targetSelection: [DemographicsTargetInput]
      $accumulatedSuggestions: [JSON]
      ){ intercectedCarrierRoutesWithSuggestions(
          after: $after
          centerSphere: $centerSphere
          radius: $radius
          targetSelection: $targetSelection
          accumulatedSuggestions: $accumulatedSuggestions
      ) {
          totalCount
          pageInfo {endCursor hasNextPage}
          suggestions
          collection {
              type
              features {
                  type
                  properties
                  geometry {
                      type
                      coordinates
                  }
              }
          }
      }
    }
  `;
  return callAuthorizedGraphQLEndpoint(gql, variables, CONFIG.PATHFINDER_API_BASE_URL);
};

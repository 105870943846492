export const ExpandIcon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 14.6663L4 10.6663L4.95 9.71634L8 12.7663L11.05 9.71634L12 10.6663L8 14.6663ZM4.96667 6.26634L4 5.33301L8 1.33301L12 5.33301L11.0333 6.26634L8 3.23301L4.96667 6.26634Z"
        fill="#4F5B94"
      />
    </svg>
  );
};

import { DMDocumentNames, DMDocumentFormats, DMDocumentDimensions } from "shared/constants";
import {
  SmallFlyerIcon,
  BestSellerFlyerIcon,
  JumboFlyerIcon,
  OversizedFlyerIcon,
  LargeFlyerIcon,
} from "components/icons/components/FlyerTypes";

export interface FlyerTypeOption {
  type: string;
  label: string;
  dimensions: string;
  worth: string;
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

export const flyerTypesOptions: FlyerTypeOption[] = [
  {
    type: DMDocumentFormats.SMALL,
    label: DMDocumentNames.SMALL,
    dimensions: DMDocumentDimensions.SMALL,
    worth: "$",
    Icon: SmallFlyerIcon,
  },
  {
    type: DMDocumentFormats.BEST_SELLER,
    label: DMDocumentNames.BEST_SELLER,
    dimensions: DMDocumentDimensions.BEST_SELLER,
    worth: "$$",
    Icon: BestSellerFlyerIcon,
  },
  {
    type: DMDocumentFormats.LARGE,
    label: DMDocumentNames.LARGE,
    dimensions: DMDocumentDimensions.LARGE,
    worth: "$$$",
    Icon: LargeFlyerIcon,
  },
  {
    type: DMDocumentFormats.JUMBO,
    label: DMDocumentNames.JUMBO,
    dimensions: DMDocumentDimensions.JUMBO,
    worth: "$$$$",
    Icon: JumboFlyerIcon,
  },
  {
    type: DMDocumentFormats.OVERSIZED,
    label: DMDocumentNames.OVERSIZED,
    dimensions: DMDocumentDimensions.OVERSIZED,
    worth: "$$$$$",
    Icon: OversizedFlyerIcon,
  },
];

import { useState, useEffect, useContext } from "react";
import { FeatureFlagContext } from "context/featureFlagContext";
import { SegmentsV1, SegmentsV2, SegmentsV3 } from "./index";

export const Segments = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { featureFlags } = useContext(FeatureFlagContext);
  const isDemographicsEnabled = featureFlags.isDemographicsEnabled;

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isMobile) return <SegmentsV1 {...props} />;
  else if (isDemographicsEnabled) return <SegmentsV3 {...props} />;
  else return <SegmentsV2 {...props} />;
};

import CONFIG from "config/config";
import { callAuthorizedGraphQLEndpoint } from "../../module/graphQL";

export const getSignedUrl = async (bucket, key, operation, region) => {
  const gql = `query($bucket: String!, $key: String!, $operation: String!, $region: String) {
    getSignedUrl(
      bucket: $bucket
      key: $key
      operation: $operation
      region: $region
    )
  }`;

  try {
    const request = await callAuthorizedGraphQLEndpoint(
      gql,
      {
        bucket,
        key,
        operation,
        region,
      },
      CONFIG.FLYER_API_URL
    );

    if (request.status === 200) {
      const {
        data: { getSignedUrl },
      } = await request.json();
      return getSignedUrl;
    }
  } catch (error) {
    return error;
  }
};

import { useState } from "react";
import cn from "classnames";
import { FlyerTypeCard } from "components/FlyerTypeSelect/FlyerTypeCard";
import { flyerTypesOptions, FlyerTypeOption } from "components/FlyerTypeSelect/flyerTypeOptions";
import { updateCampaignExtraData } from "graphQL/mutations/campaignExtraData";
import { PossibleFlyerType } from "pages/flyer/builder/drawer";
import { DMDocumentFormats } from "shared/constants";
import { makeStyles, CircularProgress } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useStore } from "store";
import { styles } from "./styles";

const useStyles = makeStyles(() => styles);

interface FlyerTypeModalProps {
  setFlyerSelectModalOpen: (value: boolean) => void;
}

export const FlyerTypeModal = ({ setFlyerSelectModalOpen }: FlyerTypeModalProps) => {
  const classes = useStyles();
  const {
    campaign: { id: campaignId, flyerType },
    updateCampaign,
  } = useStore();
  const [newFlyerType, setNewFlyerType] = useState<PossibleFlyerType>(flyerType);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const disabled = newFlyerType === flyerType;

  const handleChangeFlyerType = (newFlyerType: PossibleFlyerType) => {
    setNewFlyerType(newFlyerType);
  };

  const handleClose = () => setFlyerSelectModalOpen(false);

  const onSubmit = async () => {
    if (disabled) return;
    setSubmitting(true);
    updateCampaign({ flyerType: newFlyerType });
    await updateCampaignExtraData({
      campaignId,
      flyerType: newFlyerType,
    });
    setSubmitting(false);
    handleClose();
  };

  return (
    <div className={classes.modalBackground}>
      <div className={classes.modalContainer}>
        <div className={classes.closeContainer}>
          <CloseIcon onClick={handleClose} className={classes.closeIcon} />
        </div>
        <span className={classes.title}>Choose a postcard format</span>
        <div className={classes.optionsContainer}>
          {flyerTypesOptions.map((flyerTypeOption: FlyerTypeOption) => {
            return (
              <FlyerTypeCard
                key={flyerTypeOption.label}
                flyerTypeData={flyerTypeOption}
                selectedFlyerType={newFlyerType}
                onClick={() => {
                  handleChangeFlyerType(flyerTypeOption.type as keyof typeof DMDocumentFormats);
                }}
              />
            );
          })}
        </div>
        <span className={classes.advice}>The format affects the total cost of your campaign. </span>
        <div className={classes.footer}>
          <button className={classes.button} onClick={handleClose}>
            Cancel
          </button>
          <button
            disabled={disabled}
            className={cn(classes.button, classes.updateButton, { [classes.disabledButton]: disabled && !submitting })}
            onClick={onSubmit}
          >
            Update
            {submitting && <CircularProgress size={16} className={classes.loading} />}
          </button>
        </div>
      </div>
    </div>
  );
};

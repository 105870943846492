export const ResidencesIcon = ({ stroke, ...restProps }: { stroke?: string | null }) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path
        d="M9.8335 3.33366V13.3337H3.8335V3.00033L9.8335 1.66699V3.33366ZM9.8335 3.33366H13.1668V13.3337H14.8335"
        stroke={stroke || "#4F5B94"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="7.83366" cy="8.00065" r="0.666667" fill={stroke || "#4F5B94"} />
    </svg>
  );
};

import { IconsProps } from "shared/models";

export const Tick = ({
  width = "20",
  height = "20",
  fill = "none",
  stroke = "#04B115",
  isActive = false,
  hoverColor = "#000",
  ...props
}: IconsProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.33325 10.8333L7.49992 15L16.6666 5.83334"
        stroke={isActive ? hoverColor : stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import React from "react";

export const getTooltipPosition = ({
  containerRef,
  mousePositionCoords,
}: {
  containerRef: React.RefObject<HTMLDivElement | null>;
  mousePositionCoords: { x: number; y: number };
}) => {
  if (!containerRef?.current || !mousePositionCoords) return;

  let arrowPosition;
  const { width } = containerRef.current.getBoundingClientRect();
  let newTooltipX = mousePositionCoords.x - width / 2;
  let newTooltipY = mousePositionCoords.y < 70 ? mousePositionCoords.y + 30 : mousePositionCoords.y - 70;

  if (window.innerWidth - mousePositionCoords.x < 124) {
    newTooltipX = mousePositionCoords.x - (width + 30);
    newTooltipY = mousePositionCoords.y - 26;
    arrowPosition = "right";
  } else if (mousePositionCoords.x < 484) {
    newTooltipX = mousePositionCoords.x + width / 8;
    newTooltipY = mousePositionCoords.y - 26;
    arrowPosition = "left";
  } else {
    newTooltipX = mousePositionCoords.x - width / 2;
    newTooltipY = mousePositionCoords.y < 70 ? mousePositionCoords.y + 30 : mousePositionCoords.y - 70;
    arrowPosition = mousePositionCoords.y >= 70 ? "top" : "bottom";
  }

  const newTooltipPosition = { x: newTooltipX, y: newTooltipY };
  return { arrowPosition, newTooltipPosition };
};

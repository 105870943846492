import { IconsProps } from "shared/models";

export const Plant = ({ width = "17", height = "17", fill = "none", stroke = "#4F5B94", ...props }: IconsProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 17" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.4697 7.23551C11.7809 7.83497 10.6976 7.73282 10.1331 7.01519C9.56861 6.29757 9.72445 5.22066 10.4692 4.69251C11.1657 4.1418 13.3767 4.46575 13.3767 4.46575C13.3767 4.46575 13.1742 6.68479 12.4697 7.23551Z"
        stroke={stroke}
      />
      <path
        d="M5.19086 8.60721C5.794 9.13221 6.74275 9.04275 7.23712 8.41427C7.73111 7.78629 7.5876 6.83741 6.94275 6.38012C6.33278 5.89782 4.39648 6.18153 4.39648 6.18153C4.39648 6.18153 4.5738 8.12492 5.19086 8.60721Z"
        stroke={stroke}
      />
      <path
        d="M3.17188 12.6292C3.17188 12.6292 4.80453 10.9966 8.478 10.9966C12.1515 10.9966 13.7841 12.6292 13.7841 12.6292"
        stroke={stroke}
      />
      <path
        d="M10.2079 7.10742L10.0993 7.20885C9.28298 8.02518 8.55664 9.90564 8.47825 10.9963C8.47825 9.70412 8.22898 9.13061 7.3192 8.40034L7.2793 8.36241"
        stroke={stroke}
      />
    </svg>
  );
};

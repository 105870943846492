import React, { useContext, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import moment from "moment";
import CONFIG from "config/config";
import { debounce } from "lodash";
import { authorization } from "module/auth";
import { CAMPAIGN_TYPE_PARAM_TO_TITLE } from "shared/constants";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Typography,
  TextField,
  CircularProgress,
  Checkbox,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import { Input, Autocomplete } from "components/form";
import { LightTooltip } from "components/light-tooltip/index";
import { addDays, addBusinessDays } from "components/date-picker/utils";
import { Icon } from "components/icons";
import DatePicker from "pages/campaign/quote/component/DatePicker";
import CloseIcon from "static/media/close.svg";
import DangerIcon from "static/media/danger.svg";
import { isValidCampaignName } from "components/audience-form/components/campaign-location/utils";
import { AlertInvalidMissions } from "components/audience-form/components/campaign-location/h2h-precise-locations/components/AlertInvalidMissions";
import { FeatureFlagContext } from "context/featureFlagContext";
import { filterCoupons, getDynamicCoupons } from "../../campaign-data-hoc/utils";
import { REGEX_FOR_CAMPAIGN_NAME, DM_MIN_SELECTABLE_DATE } from "../../constants";
import { getEarliestStartDate, openLinkInSameTab, prepareCouponIdForDB } from "./utils.js";
import isValidUrl from "../../utils/isValidurl";
import { updateCampaignExtraData, updateCampaign as updateCampaignDB } from "../../graphQL";
import useCalculating from "../../Hooks/useCalculating";

import style from "./style.js";
import { useStore } from "../../store";

const DetailsForm = ({
  classes,
  isFlyerUploaded,
  qrCodeNotRequired,
  setQrCodeNotRequired,
  checkingCampaignName,
  setCheckingCampaignName,
}) => {
  const {
    city,
    client: { id: clientId },
    campaign: {
      id: campaignId,
      campaignName,
      isD2D,
      isH2H,
      isDM,
      startDate,
      isSubmitted,
      campaignComments = "",
      qrCodeLink,
      isTrackableCoupon: isTrackableCouponDB,
      couponType: couponTypeDB,
      staticCouponCode: staticCouponCodeDB,
      dynamicCouponCode: dynamicCouponCodeDB,
      channel,
      purchaseOrderNumber,
    },
    missions,
    country,
    distributionLocations,
    errors: { qrCodeUrlError, launchDateError, campaignNameError, notExistDesignFile, purchaseOrderNumberError },
    timezone,
    updateErrors,
    updateCampaign,
    updateCouponsList,
  } = useStore();

  const { recalculateCampaignCost } = useCalculating();

  const isClientAdmin = authorization.isClientAdmin();
  const isAdmin = authorization.isAdmin();

  const [currentCampaignName, setCurrentCampaignName] = useState(campaignName);

  const [isTrackableCoupon, setIsTrackableCoupon] = useState(isAdmin ? 0 : isTrackableCouponDB);
  const [couponType, setCouponType] = useState(couponTypeDB);
  const [staticCouponCode, setStaticCouponCode] = useState(staticCouponCodeDB);
  const [dynamicCouponCode, setDynamicCouponCode] = useState(null);
  const [isCouponsLoading, setIsCouponsLoading] = useState(true);
  const [couponsList, setCouponsList] = useState([]);
  const [isPurchaseOrderNumberRequired, setIsPurchaseOrderNumberRequired] = useState(!!purchaseOrderNumber);
  const { featureFlags } = useContext(FeatureFlagContext);

  const isSkipEnabled = featureFlags.isSkipEnabled;
  const isUs = useMemo(() => (country ? country.code === "US" : false), [country]);
  const defaultCampaignLimits = isDM ? DM_MIN_SELECTABLE_DATE : country.countryDefaultSettings.defaultCampaignLimits;

  const channelToTitle = CAMPAIGN_TYPE_PARAM_TO_TITLE[channel] || "";
  const isDefaultName =
    campaignName.includes(`${channelToTitle} Campaign - `) || campaignName.includes(`${channelToTitle} - Campaign - `);

  useEffect(() => {
    if (couponTypeDB === "dynamic") {
      fetchDynamicCoupons();
    }
  }, []);

  useEffect(() => {
    if (isH2H) {
      const earliestStartMissionDate = getEarliestStartDate(city.addresses);
      updateErrors({ launchDateError: null });

      if (earliestStartMissionDate) {
        updateCampaign({ startDate: earliestStartMissionDate });
      }
    } else if ((isD2D || isDM) && startDate && !isSubmitted) {
      checkLaunchDate(startDate);
    }
    recalculateCampaignCost();
  }, []);

  useEffect(() => {
    if (campaignName && !currentCampaignName && !isDefaultName) {
      setCurrentCampaignName(campaignName);
    }
    if (campaignName === currentCampaignName) {
      updateErrors({ campaignNameError: null });
    }
  }, [campaignName, isDefaultName]);

  const validateAndSaveCampaignNameDebounced = debounce(() => {
    handleSaveCampaignName();
  }, 1000);

  useEffect(() => {
    if (!currentCampaignName || currentCampaignName === campaignName) return;
    setCheckingCampaignName(true);
    validateAndSaveCampaignNameDebounced();
    return () => {
      validateAndSaveCampaignNameDebounced.cancel();
    };
  }, [currentCampaignName]);

  useEffect(() => {
    if (qrCodeNotRequired) {
      updateErrors({ qrCodeUrlError: null });
      updateCampaign({ qrCodeLink: "" });
    } else {
      if (!qrCodeLink) {
        updateErrors({ qrCodeUrlError: "URL is required" });
      } else if (!isValidUrl(qrCodeLink)) {
        updateErrors({ qrCodeUrlError: "Invalid URL" });
      } else if (qrCodeUrlError && isValidUrl(qrCodeLink)) {
        updateErrors({ qrCodeUrlError: null });
      }
    }
  }, [qrCodeNotRequired, qrCodeLink]);

  const validateCampaignName = (name) => {
    const cleanName = name.replace(REGEX_FOR_CAMPAIGN_NAME, "");
    if (cleanName.length > 46) {
      updateErrors({ campaignNameError: "Campaign name should not exceed 46 characters" });
    } else {
      updateErrors({ campaignNameError: null });
    }
    return cleanName;
  };

  const checkLaunchDate = (newDate) => {
    // Wee need to validate the difference between days without taking into account the hours
    const newMomentDateWithoutHours = moment(newDate).format("DD/MM/YYYY");
    const fourteenDaysFromNowWithoutHours = moment(addBusinessDays(new Date(), defaultCampaignLimits, timezone)).format(
      "DD/MM/YYYY"
    );
    const daysDiff = moment(newMomentDateWithoutHours, "DD/MM/YYYY", true).diff(
      moment(fourteenDaysFromNowWithoutHours, "DD/MM/YYYY", true),
      "days"
    );

    if (daysDiff < 0) {
      updateErrors({ launchDateError: "Select a different date" });
      // updateCampaign({ startDate: "" });
      return;
    }

    updateErrors({ launchDateError: null });
    updateCampaign({ startDate: newDate });
  };

  const handleDateChange = (newDate) => {
    if (isD2D || isDM) {
      checkLaunchDate(newDate);
    }
  };

  const handleQrCodeUrlChange = (url) => {
    const isValid = isValidUrl(url);
    if (!isValid && !qrCodeNotRequired) {
      updateErrors({ qrCodeUrlError: "Invalid URL" });
    } else if (qrCodeUrlError) {
      updateErrors({ qrCodeUrlError: null });
    }
    updateCampaign({ qrCodeLink: url });
  };

  const handlePurchaseOrderNumberError = (hasError) => {
    if (hasError) {
      updateErrors({ purchaseOrderNumberError: "Purchase order number is required" });
    } else {
      updateErrors({ purchaseOrderNumberError: null });
    }
  };

  const handleChangePurchaseOrderNumberRequired = (value) => {
    if (!value) {
      updateCampaign({ purchaseOrderNumber: null });
      handlePurchaseOrderNumberError(false);
    } else if (value && !purchaseOrderNumber) {
      handlePurchaseOrderNumberError(true);
    }
    setIsPurchaseOrderNumberRequired(value);
  };

  const handlePurchaseOrderNumberChange = (value) => {
    handlePurchaseOrderNumberError(isPurchaseOrderNumberRequired && !value);
    updateCampaign({ purchaseOrderNumber: value });
  };

  const handleSaveCampaignName = async () => {
    setCheckingCampaignName(true);
    const trimmedCampaignName = currentCampaignName.trim();
    const noSpaceCampaignName = currentCampaignName.replace(/\s/g, "");
    if (!trimmedCampaignName) {
      // setCurrentCampaignName(campaignName);
      updateErrors({ campaignNameError: null });

      setCheckingCampaignName(false);
      return;
    } else {
      if (noSpaceCampaignName.length < 4) {
        updateErrors({ campaignNameError: "Campaign name is too short (minimum 4 characters)" });

        setCheckingCampaignName(false);
        return;
      }

      if (trimmedCampaignName.length > 46) {
        updateErrors({ campaignNameError: "Campaign name should not exceed 46 characters" });

        setCheckingCampaignName(false);
        return;
      }
      const isLangError = REGEX_FOR_CAMPAIGN_NAME.test(trimmedCampaignName);

      if (isLangError) {
        updateErrors({ campaignNameError: "Campaign name contains invalid characters." });

        setCheckingCampaignName(false);
        return;
      }

      const isValid = await isValidCampaignName({ campaignName: trimmedCampaignName, clientId, campaignId });

      if (isValid) {
        updateErrors({ campaignNameError: null });
        updateCampaign({ campaignName: currentCampaignName });
        await updateCampaignDB({
          campaignId,
          name: currentCampaignName,
        });
      } else {
        updateErrors({ campaignNameError: "Duplicated campaign name. Please choose another." });
      }

      setCheckingCampaignName(false);
    }
  };

  const fetchDynamicCoupons = async () => {
    setIsCouponsLoading(true);

    const result = await getDynamicCoupons();
    let existingCouponId = dynamicCouponCodeDB ? JSON.parse(dynamicCouponCodeDB).couponId : null;

    const coupons = filterCoupons(result, existingCouponId);
    const mappedCoupons = coupons.map((coupon) => ({
      ...coupon,
      // Have some required data encoded into ID
      id: prepareCouponIdForDB(coupon),
    }));
    setCouponsList(mappedCoupons);
    updateCouponsList(mappedCoupons);
    setIsCouponsLoading(false);
  };

  const handleIsTrackableCouponChange = (value) => {
    setIsTrackableCoupon(+value);
    updateCampaign({ isTrackableCoupon: +value });
    updateCampaignExtraData({ campaignId, isTrackableCoupon: +value });

    if (isDM && value) {
      handleCouponTypeChange("static");
    }
  };

  const handleCouponTypeChange = (value) => {
    if (value === "dynamic" && !couponsList.length) {
      fetchDynamicCoupons();
    }
    setCouponType(value);
    updateCampaign({ couponType: value });
    updateCampaignExtraData({ campaignId, couponType: value });
  };

  useEffect(() => {
    if (couponType === "dynamic" && couponsList.length) {
      const selectedDynamicCouponCode = couponsList.filter((coupon) => coupon.id === dynamicCouponCodeDB);
      setDynamicCouponCode(selectedDynamicCouponCode?.length ? selectedDynamicCouponCode[0] : null);
    }
  }, [isCouponsLoading]);

  useEffect(() => {
    if ((couponType === "dynamic" && !dynamicCouponCode) || (couponType === "static" && !staticCouponCode)) {
      updateErrors({ noCouponCode: true });
    } else {
      updateErrors({ noCouponCode: false });
    }
  }, [couponType, dynamicCouponCode, staticCouponCode]);

  const handleSelectCouponCode = (coupon) => {
    setDynamicCouponCode(coupon);
    updateCampaign({ dynamicCouponCode: coupon ? coupon.id : null });
  };

  const onCampaignNameBlur = () => {
    if (!currentCampaignName && !isDefaultName) {
      setCurrentCampaignName(campaignName);
    }
  };

  return (
    <Grid container justify="center" alignContent="flex-start">
      <Grid xs={12} container item justify="flex-end" direction="column">
        <Box width="100%" display={"flex"} alignSelf="flex-end" justifyContent="space-between">
          <Box display="flex" alignItems={"center"}>
            <Typography variant="caption" component="p" gutterBottom className={classes.inputName}>
              Launch date
            </Typography>
            <LightTooltip
              placement="top-start"
              title={
                <>
                  <Typography className={classes.font_size_14} gutterBottom>
                    {`Launch date must be set not earlier than ${defaultCampaignLimits}${isDM ? " business" : ""} day${
                      defaultCampaignLimits === 1 ? "" : "s"
                    } from today`}
                  </Typography>
                </>
              }
            >
              <Box ml="12px">
                <Icon type="InfoEmpty" size="xs" />
              </Box>
            </LightTooltip>
          </Box>

          <Box>{(isD2D || isDM) && !startDate && <span className={classes.required}>Required</span>}</Box>
        </Box>
        <DatePicker
          value={startDate && !isNaN(+startDate) ? startDate : null}
          onChange={handleDateChange}
          error={undefined}
          outlined
          minDate={addBusinessDays(new Date(), defaultCampaignLimits, timezone)}
          maxDate={addDays(new Date(), 360, timezone)}
          isUS={isUs}
          disabled={isH2H || isSubmitted}
          className={classes.datePicker}
        />
        {launchDateError ? (
          isD2D || isDM ? (
            <Box className={classes.error}>
              <AlertInvalidMissions modalType={1} defaultCampaignLimits={defaultCampaignLimits} />
            </Box>
          ) : (
            <Box className={classes.h2hDateErrorContainer}>
              <Box className={classes.h2hDateErrorTitleContainer}>
                <Typography className={classes.h2hDateErrorTitle}>Launch date has expired</Typography>{" "}
                <Typography
                  onClick={() => {
                    console.log("llevame a audience");
                  }}
                  className={cn(classes.h2hDateErrorTitle, classes.h2hDateErrorCtaButton)}
                >
                  Edit audience
                </Typography>
              </Box>
              <Box className={classes.h2hDateErrorMessage}>
                <Typography variant="p">{launchDateError}</Typography>
              </Box>
            </Box>
          )
        ) : null}
      </Grid>
      <Grid xs={12} item>
        <Box className={classes.relative} width="100%" mt="16px" alignSelf="flex-end">
          <Typography variant="caption" component="p" gutterBottom className={classes.inputName}>
            Campaign name
          </Typography>
          <Input
            style={{ color: "black" }}
            name="campaignName"
            value={currentCampaignName}
            placeholder={campaignName}
            variant="outlined"
            onChange={(value) => setCurrentCampaignName(validateCampaignName(value))}
            disabled={isSubmitted}
            onBlur={onCampaignNameBlur}
            cypressID={"campaignName-input"}
          />
          {!isSubmitted && (
            <Box display={"flex"} justifyContent={"end"}>
              <Box display={"flex"}>
                {checkingCampaignName ? (
                  <CircularProgress style={{ position: "absolute", top: 0, right: 0 }} size={18} color="secondary" />
                ) : // <div style={{ position: "absolute", top: 0, right: 0 }}>Name saved</div>
                null}
              </Box>
            </Box>
          )}
          {campaignNameError && <Box className={classes.error}>{campaignNameError}</Box>}
        </Box>
      </Grid>
      <Grid xs={12} item>
        <Box width="100%" mt="16px" alignSelf="flex-end">
          <Box display="flex">
            <Typography variant="caption" component="p" gutterBottom className={classes.inputName}>
              URL associated with the QR code(s) on the flyer
            </Typography>
            {(!isFlyerUploaded || !qrCodeNotRequired) && <span className={classes.required}> *</span>}
            <LightTooltip
              placement="top-start"
              title={
                <>
                  <Typography className={classes.font_size_11} gutterBottom>
                    In order to ensure accurate tracking of QR scans, please provide the URL associated with your QR
                    code.
                  </Typography>
                </>
              }
            >
              <Box ml="12px">
                <Icon type="InfoEmpty" size="xs" />
              </Box>
            </LightTooltip>
          </Box>
          <Input
            name="qrCodeLink"
            value={qrCodeNotRequired ? "" : qrCodeLink ? qrCodeLink : "https://"}
            variant="outlined"
            onChange={handleQrCodeUrlChange}
            disabled={!isFlyerUploaded || qrCodeNotRequired || isSubmitted}
            placeholder="https://www.yourwebsite.com"
            cypressID={"qrCodeLink-input"}
          />
          {qrCodeUrlError && <Box className={classes.error}>{qrCodeUrlError}</Box>}
          {(isFlyerUploaded || isSkipEnabled) && (
            <label>
              <Box className={classes.qrCheckboxContainer}>
                <Checkbox
                  size="small"
                  checked={qrCodeNotRequired}
                  onChange={(e) => {
                    setQrCodeNotRequired(e.target.checked);
                  }}
                  className={classes.qrCheckbox}
                  disabled={isSubmitted}
                  cypress_id={"qrCodeLink-checkbox"}
                />
                <Typography className={classes.qrCheckboxMessage}>I don't have a QR in my campaign</Typography>
              </Box>
            </label>
          )}
        </Box>
      </Grid>
      {!isAdmin ? (
        <Grid xs={12} item>
          <Box width="100%" mt="16px" alignSelf="flex-end">
            <Box display="flex">
              <Typography variant="caption" component="p" gutterBottom className={classes.inputName}>
                Would you like to add a trackable coupon?
              </Typography>
            </Box>
            <RadioGroup
              aria-label="isTrackableCoupon"
              name="isTrackableCoupon"
              value={isTrackableCoupon}
              onChange={(event, value) => handleIsTrackableCouponChange(value)}
              row
            >
              <FormControlLabel value={1} control={<Radio disabled={isSubmitted} />} label={"Yes"} />
              <FormControlLabel value={0} control={<Radio disabled={isSubmitted} />} label={"No"} />
            </RadioGroup>
          </Box>
        </Grid>
      ) : null}

      {isTrackableCoupon ? (
        <>
          <Grid xs={12} item>
            <Box width="100%" mt="16px" alignSelf="flex-end">
              <Box display="flex">
                <Typography variant="caption" component="p" gutterBottom className={classes.inputName}>
                  What type of coupon would you like to add?
                </Typography>
              </Box>
              <RadioGroup
                aria-label="couponType"
                name="couponType"
                value={couponType}
                onChange={(event, value) => handleCouponTypeChange(value)}
                row
              >
                <Box display="flex">
                  <FormControlLabel value={"static"} control={<Radio disabled={isSubmitted} />} label={"Static"} />
                  <LightTooltip
                    className={classes.mob_tooltip}
                    placement="top-start"
                    title={
                      <>
                        <Typography
                          className={classes.font_size_11}
                        >{`Static coupon codes use one single coupon code across all flyers in your campaign, allowing basic tracking but with limited accuracy. `}</Typography>
                      </>
                    }
                  >
                    <Box>
                      <Icon type="InfoEmpty" size="xs" />
                    </Box>
                  </LightTooltip>
                </Box>
                {!isDM ? (
                  <Box display="flex" ml="16px">
                    <FormControlLabel value={"dynamic"} control={<Radio disabled={isSubmitted} />} label={"Dynamic"} />
                    <LightTooltip
                      className={classes.mob_tooltip}
                      placement="top-start"
                      title={
                        <>
                          <Typography className={classes.font_size_11}>
                            {`Dynamic coupon codes are individually assigned to each flyer or batch of flyers, enabling highly precise tracking, and optimization of your campaign.`}
                          </Typography>
                        </>
                      }
                    >
                      <Box>
                        <Icon type="InfoEmpty" size="xs" />
                      </Box>
                    </LightTooltip>
                  </Box>
                ) : null}
              </RadioGroup>
            </Box>
          </Grid>
          {!couponType || couponType === "static" ? (
            <Grid xs={12} item>
              <Box width="100%" mt="16px" alignSelf="flex-end">
                <Box display="flex">
                  <Typography variant="caption" component="p" gutterBottom className={classes.inputName}>
                    Add a coupon code for us to track redemptions
                  </Typography>
                </Box>
                <Input
                  name="staticCouponCode"
                  value={staticCouponCode}
                  variant="outlined"
                  onChange={(value) => {
                    setStaticCouponCode(value ? value : null);
                    updateCampaign({ staticCouponCode: value ? value : null });
                  }}
                  placeholder="Example: PROMO50"
                  disabled={isSubmitted}
                  cypressID={"couponCode-input"}
                />
                {!staticCouponCode && <Box className={classes.error}>Coupon is required</Box>}
              </Box>
            </Grid>
          ) : (
            <Grid xs={12} item>
              {!isSubmitted ? (
                <Box width="100%" mt="16px" display="flex" alignItems="center">
                  <img src={DangerIcon} style={{ marginRight: "4px" }} />
                  <Typography variant="body" component="p">
                    {missions.length} coupon codes are needed for this campaign
                  </Typography>
                </Box>
              ) : null}
              <Box width="100%" mt="16px" alignSelf="flex-end">
                <Box display="flex" flexDirection="column">
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="caption" component="p" gutterBottom className={classes.inputName}>
                      Select your coupons from the created coupons:
                    </Typography>
                    {isClientAdmin && !isSubmitted ? (
                      <span
                        onClick={() =>
                          openLinkInSameTab(
                            `${CONFIG.PLATFORM_BASE_URL}/dashboard/client-settings/coupons/stores?redirectURL=${window.location.href}`
                          )
                        }
                        className={classes.link}
                      >
                        Add coupons
                      </span>
                    ) : null}
                  </Box>
                  <Autocomplete
                    name="coupons"
                    label={isCouponsLoading ? "Loading" : "Coupons list"}
                    value={dynamicCouponCode}
                    getOptionLabel={(item) => item.name}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionDisabled={(option) => option.codesCount < missions.length}
                    renderOption={(option) => (
                      <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                        <Typography>{option.name}</Typography>
                        <Typography variant="caption">{`${option.codesCount} code(s)`}</Typography>
                      </Box>
                    )}
                    loading={isCouponsLoading}
                    options={couponsList}
                    onChange={handleSelectCouponCode}
                    hideLabel
                    disabled={isSubmitted}
                  />
                </Box>
              </Box>
            </Grid>
          )}
        </>
      ) : null}
      <Grid xs={12} item>
        <Box width="100%" mt="16px" alignSelf="flex-end">
          <Box className={classes.qrCheckboxContainer}>
            <Checkbox
              size="small"
              checked={isPurchaseOrderNumberRequired}
              onChange={(e) => {
                handleChangePurchaseOrderNumberRequired(e.target.checked);
              }}
              className={classes.qrCheckbox}
              disabled={isSubmitted}
              cypress_id={"qrCodeLink-checkbox"}
            />
            <Typography className={classes.qrCheckboxMessage}>Include purchase order number (optional)</Typography>
          </Box>
        </Box>
      </Grid>
      {isPurchaseOrderNumberRequired ? (
        <Grid xs={12} item>
          <Box className={classes.relative} width="100%" mt="16px" alignSelf="flex-end">
            <Typography variant="caption" component="p" gutterBottom className={classes.inputName}>
              Purchase order number
            </Typography>
            <Input
              style={{ color: "black" }}
              name="purchaseOrderNumber"
              value={purchaseOrderNumber}
              placeholder="e.g.: PO-000-000"
              variant="outlined"
              onChange={handlePurchaseOrderNumberChange}
              disabled={isSubmitted}
              cypressID={"purchaseOrderNumber-input"}
            />
            {purchaseOrderNumberError && <Box className={classes.error}>{purchaseOrderNumberError}</Box>}
          </Box>
        </Grid>
      ) : null}
      <Grid xs={12} item>
        <Box width="100%" mt="16px" alignSelf="flex-end">
          <Typography variant="caption" component="p" gutterBottom className={classes.inputName}>
            Comments
          </Typography>
          <TextField
            placeholder="Feel free to leave your questions or comments regarding this campaign."
            className={classes.commentsInput}
            variant="outlined"
            value={campaignComments || ""}
            onChange={(e) => {
              updateCampaign({ campaignComments: e.target.value });
            }}
            error={false}
            multiline
            rows={6}
            inputProps={{ maxLength: 240 }}
            disabled={isSubmitted}
            cypress_id={"campaignComments-input"}
          />
          <Box>
            <Typography variant="caption" component="p" gutterBottom className={classes.inputName}>
              {campaignComments ? campaignComments.length : 0} of 240 Symbols
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default withStyles(style)(DetailsForm);

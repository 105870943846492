export const CollapseIcon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.93333 13.6663L4 12.733L8 8.73301L12 12.733L11.0667 13.6663L8 10.5997L4.93333 13.6663ZM8 7.26634L4 3.26634L4.93333 2.33301L8 5.39967L11.0667 2.33301L12 3.26634L8 7.26634Z"
        fill="#4F5B94"
      />
    </svg>
  );
};

import { IconsProps } from "shared/models";

export const SuggestionStart = ({
  width = "17",
  height = "17",
  fill = "none",
  stroke = "#04B115",
  isActive = false,
  hoverColor = "#000",
  ...props
}: IconsProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 17" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.08035 2.63421C7.2916 2.15921 7.94223 2.13608 8.19723 2.56296L8.2341 2.63421L8.88535 4.09983C9.45402 5.37916 10.3997 6.45437 11.596 7.18171L11.7972 7.29983L12.9535 7.94983C13.0454 8.00129 13.123 8.07494 13.1791 8.16406C13.2353 8.25318 13.2683 8.35494 13.2751 8.46006C13.2818 8.56519 13.2622 8.67034 13.2179 8.76592C13.1736 8.86151 13.1062 8.94451 13.0216 9.00733L12.9541 9.05108L11.7972 9.70108C10.5768 10.3876 9.59515 11.4303 8.98348 12.6898L8.88535 12.9011L8.2341 14.3667C8.02285 14.8417 7.37223 14.8648 7.11723 14.438L7.08035 14.3667L6.4291 12.9011C5.86043 11.6218 4.91474 10.5465 3.71848 9.81921L3.51723 9.70108L2.36098 9.05108C2.26906 8.99962 2.19148 8.92598 2.13531 8.83686C2.07914 8.74774 2.04616 8.64598 2.03939 8.54086C2.03262 8.43573 2.05226 8.33058 2.09653 8.23499C2.14081 8.1394 2.20829 8.05641 2.29285 7.99358L2.36035 7.94983L3.51723 7.29983C4.73761 6.61336 5.7193 5.57062 6.33098 4.31108L6.4291 4.09983L7.08035 2.63421ZM7.65723 4.41421C6.87973 6.16421 5.60348 7.56046 3.93223 8.50046C5.60348 9.44046 6.87973 10.8367 7.65723 12.5867C8.43723 10.8323 9.71348 9.43921 11.3822 8.50046C9.71098 7.56046 8.43473 6.16421 7.65723 4.41421ZM12.7947 2.32671C13.1052 3.03132 13.6345 3.61714 14.3041 3.99733C14.4041 4.05358 14.4041 4.19733 14.3041 4.25358C13.6343 4.6341 13.105 5.22038 12.7947 5.92546C12.7829 5.952 12.7636 5.97454 12.7392 5.99036C12.7148 6.00617 12.6863 6.01459 12.6572 6.01459C12.6281 6.01459 12.5997 6.00617 12.5753 5.99036C12.5509 5.97454 12.5316 5.952 12.5197 5.92546C12.2093 5.22061 11.68 4.63457 11.0104 4.25421C10.9877 4.24132 10.969 4.22267 10.9559 4.20017C10.9428 4.17767 10.9359 4.15211 10.9359 4.12608C10.9359 4.10006 10.9428 4.0745 10.9559 4.052C10.969 4.02949 10.9877 4.01085 11.0104 3.99796C11.6802 3.61728 12.2095 3.03077 12.5197 2.32546C12.5316 2.29892 12.5509 2.27638 12.5753 2.26056C12.5997 2.24474 12.6281 2.23633 12.6572 2.23633C12.6863 2.23633 12.7148 2.24474 12.7392 2.26056C12.7636 2.27638 12.7829 2.29892 12.7947 2.32546V2.32671Z"
        fill={stroke}
      />
    </svg>
  );
};

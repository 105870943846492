export const stylesV2 = {
  addressesContainer: {
    position: "absolute" as const,
    left: "0px",
    top: "0px",
    backgroundColor: "#FFFFFF",
    width: "360px",
    height: "calc(100vh - 80px)",
    display: "flex",
    flexDirection: "column" as const,
  },
  addressBlockHeader: {
    borderTop: "1px solid #E2E2FC",
    borderBottom: "1px solid #E2E2FC",
    padding: "16px",
  },
  locationsCardsContainer: {
    width: "100%",
    overflow: "scroll",
    padding: "20px 16px",
  },
  hideScroll: {
    overflow: "hidden",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    fontWeight: 400,
    fontFamily: "'Proxima Nova', 'sans-serif'",
    fontSize: "14px",
    color: "#2E3860",
    marginBottom: "5px",
  },
  searchBarWrapper: {
    display: "flex",
    width: "100%",
  },
  selectedRoutes: {
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
  },
  selectedRoutesCheck: {
    marginRight: "8px",
  },
  selectedLocationsBlock: {
    height: "100%",
    border: "1px solid #B9C6E4",
    borderBottom: "none",
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
    paddingTop: "8px",
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
  },
  expandLocationsBlockButton: {
    width: "46px",
    height: "4px",
    backgroundColor: "#E2E2FC",
    marginBottom: "10px",
    borderRadius: "4px",
    cursor: "pointer",
  },
  emptyStateContainer: {
    height: "50%",
    flexDirection: "column" as const,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  emptyStateText: {
    width: "184px",
    marginTop: "10px",
    textAlign: "center" as const,
  },
};

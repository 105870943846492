import { IconsProps } from "shared/models";

export const HouseholdOwnership = ({
  width = "14",
  height = "14",
  fill = "none",
  stroke = "#2E3860",
  ...props
}: IconsProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.4616 5.14282L17.0199 6.58453M17.0199 6.58453L19.1825 8.74709L16.6595 11.2701L14.4969 9.10752M17.0199 6.58453L14.4969 9.10752M11.5342 12.0702C11.9064 12.4375 12.2023 12.8747 12.4048 13.3568C12.6073 13.8389 12.7125 14.3562 12.7142 14.8791C12.716 15.402 12.6143 15.9201 12.415 16.4035C12.2157 16.8869 11.9228 17.3261 11.553 17.6959C11.1833 18.0656 10.7441 18.3586 10.2607 18.5579C9.77724 18.7571 9.25918 18.8588 8.73629 18.8571C8.21341 18.8553 7.69604 18.7502 7.21396 18.5476C6.73189 18.3451 6.29464 18.0492 5.92739 17.677C5.20519 16.9293 4.80557 15.9278 4.8146 14.8883C4.82364 13.8487 5.2406 12.8543 5.97569 12.1192C6.71077 11.3842 7.70517 10.9672 8.7447 10.9582C9.78423 10.9491 10.7857 11.3487 11.5335 12.0709L11.5342 12.0702ZM11.5342 12.0702L14.4969 9.10752"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

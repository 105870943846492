export const EditIcon = (props) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.125 14.875L15.25 4.74998C15.447 4.553 15.6032 4.31915 15.7098 4.06178C15.8165 3.80441 15.8713 3.52856 15.8713 3.24998C15.8713 2.97141 15.8165 2.69556 15.7098 2.43819C15.6032 2.18082 15.447 1.94697 15.25 1.74998C15.053 1.553 14.8192 1.39674 14.5618 1.29014C14.3044 1.18353 14.0286 1.12866 13.75 1.12866C13.4714 1.12866 13.1956 1.18353 12.9382 1.29014C12.6808 1.39674 12.447 1.553 12.25 1.74998L2.125 11.875M5.125 14.875L1 16L2.125 11.875M5.125 14.875L2.125 11.875"
        stroke="#4F5B94"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

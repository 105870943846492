import React from "react";

export const LargeFlyerIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="57" height="38" viewBox="0 0 57 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_d_3739_16942)">
        <path
          d="M4.0166 4.61133C4.0166 3.50676 4.91203 2.61133 6.0166 2.61133H46.1877L52.8081 9.50343V29.2629C52.8081 30.3674 51.9127 31.2629 50.8081 31.2629H6.0166C4.91203 31.2629 4.0166 30.3674 4.0166 29.2629V4.61133Z"
          fill="white"
        />
        <path
          d="M4.0166 4.61133C4.0166 3.50676 4.91203 2.61133 6.0166 2.61133H22.291V31.2631H6.0166C4.91203 31.2631 4.0166 30.3677 4.0166 29.2631V4.61133Z"
          fill="#E2E2FC"
        />
        <rect x="8.52637" y="24.3008" width="9.25555" height="0.988129" fill="white" />
        <rect x="7.26074" y="26.4424" width="11.5986" height="0.988125" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.897 21.7042V23.377H29.5266V21.7042H27.897ZM27.2451 21.6624C27.2451 21.316 27.5187 21.0352 27.8562 21.0352H29.5674C29.9049 21.0352 30.1785 21.316 30.1785 21.6624V23.4188C30.1785 23.7652 29.9049 24.0461 29.5674 24.0461H27.8562C27.5187 24.0461 27.2451 23.7652 27.2451 23.4188V21.6624ZM27.897 25.3841V27.0568H29.5266V25.3841H27.897ZM27.2451 25.3423C27.2451 24.9958 27.5187 24.715 27.8562 24.715H29.5674C29.9049 24.715 30.1785 24.9958 30.1785 25.3423V27.0986C30.1785 27.4451 29.9049 27.7259 29.5674 27.7259H27.8562C27.5187 27.7259 27.2451 27.4451 27.2451 27.0986V25.3423ZM31.4826 23.377V21.7042H33.1123V23.377H31.4826ZM31.4419 21.0352C31.1044 21.0352 30.8307 21.316 30.8307 21.6624V23.4188C30.8307 23.7652 31.1044 24.0461 31.4419 24.0461H33.153C33.4905 24.0461 33.7641 23.7652 33.7641 23.4188V21.6624C33.7641 21.316 33.4905 21.0352 33.153 21.0352H31.4419ZM31.1569 24.715C31.3369 24.715 31.4828 24.8648 31.4828 25.0496V26.0532C31.4828 26.238 31.3369 26.3877 31.1569 26.3877C30.9768 26.3877 30.8309 26.238 30.8309 26.0532V25.0496C30.8309 24.8648 30.9768 24.715 31.1569 24.715ZM32.7865 25.0496C32.7865 24.8648 32.6406 24.715 32.4606 24.715C32.2806 24.715 32.1347 24.8648 32.1347 25.0496V25.7052C32.1344 25.7101 32.1343 25.715 32.1343 25.7199C32.1343 25.7249 32.1344 25.7298 32.1347 25.7347V27.0568H31.1569C30.9768 27.0568 30.8309 27.2066 30.8309 27.3914C30.8309 27.5761 30.9768 27.7259 31.1569 27.7259H32.4606C32.6406 27.7259 32.7865 27.5761 32.7865 27.3914V26.0545H33.4381C33.6181 26.0545 33.764 25.9047 33.764 25.7199C33.764 25.5352 33.6181 25.3854 33.4381 25.3854H32.7865V25.0496ZM33.7634 26.7226C33.7634 26.5378 33.6175 26.3881 33.4375 26.3881C33.2575 26.3881 33.1116 26.5378 33.1116 26.7226V27.3917C33.1116 27.5765 33.2575 27.7262 33.4375 27.7262C33.6175 27.7262 33.7634 27.5765 33.7634 27.3917V26.7226Z"
          fill="#4F5B94"
        />
        <path
          d="M6.19141 8.75C6.19141 7.64543 7.08684 6.75 8.19141 6.75H17.6607C18.7653 6.75 19.6607 7.64543 19.6607 8.75V20.3091H8.1914C7.08683 20.3091 6.19141 19.4136 6.19141 18.3091V8.75Z"
          fill="#4F5B94"
        />
        <ellipse cx="12.9265" cy="11.9433" rx="3.34931" ry="3.49112" fill="white" />
        <path d="M6.19141 15.2891L19.6607 19.6238V20.5647H6.19141V15.2891Z" fill="#AEAFC7" />
        <path
          d="M19.6611 13.8135L6.19182 19.5409C6.19182 20.6116 7.05975 21.4795 8.1304 21.4795H17.6611C18.7657 21.4795 19.6611 20.5841 19.6611 19.4795V13.8135Z"
          fill="white"
        />
        <rect x="27.0264" y="7.81641" width="15.7379" height="3.10049" fill="#E2E2FC" />
        <rect x="27.0264" y="12.9268" width="11.6058" height="1.22053" fill="#E2E2FC" />
        <rect x="27.0264" y="15.0098" width="13.4286" height="1.22053" fill="#E2E2FC" />
        <rect x="27.0264" y="17.0938" width="12.2136" height="1.22053" fill="#E2E2FC" />
        <rect x="36.0498" y="22.7285" width="10.7077" height="1.22053" fill="#E2E2FC" />
        <rect x="36.0498" y="24.8115" width="9.73884" height="1.22053" fill="#E2E2FC" />
        <path d="M46.2217 2.61133L52.9839 9.6185H46.2217V2.61133Z" fill="#4F5B94" />
      </g>
      <defs>
        <filter
          id="filter0_d_3739_16942"
          x="0.0166016"
          y="0.611328"
          width="56.9678"
          height="36.6514"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0745098 0 0 0 0 0.0588235 0 0 0 0 0.14902 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3739_16942" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3739_16942" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

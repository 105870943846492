import { formatNumber, toFixed2 } from "utils/formators";
import Skeleton from "@material-ui/lab/Skeleton";
import { StarIcon } from "components/icons/components/Star";
import { useState, useEffect, useRef, useMemo } from "react";
import { ResidencesIcon } from "components/icons/components/ResidencesIcon";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import { RouteIcon } from "components/icons/components/RouteIcon";
import { makeStyles } from "@material-ui/core";
import cn from "classnames";
import { useEDDMCostsCalculationV2 } from "Hooks/useEDDMCostsCalculationV2";
import { getTooltipPosition } from "./utils";
import { styles } from "./styles";

const useStyles = makeStyles(() => styles);

export const AreaStatisticsV2 = ({
  isCalculatingRoutesIntercection,
  intersectedRoutesRef,
  mousePositionCoords,
  selectedTargets,
}) => {
  const classes = useStyles();
  const { calculateIntercectedRoutesCost } = useEDDMCostsCalculationV2();
  const [isOpen, setIsOpen] = useState(false);
  const [totalCost, setTotalCost] = useState(null);
  const [fitScore, setFitScore] = useState(null);
  const [blockData, setBlockData] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState(null);
  const [tooltipArrowPosition, setTooltipArrowPosition] = useState("bottom");

  const containerRef = useRef(null);
  const currentContainerRefBounds = useMemo(
    () => containerRef.current?.getBoundingClientRect(),
    [containerRef.current]
  );

  useEffect(() => {
    if (isCalculatingRoutesIntercection) {
      setBlockData(null);
      setTotalCost(null);
    } else if (!isCalculatingRoutesIntercection && !!intersectedRoutesRef.current) {
      const { cost, business, routes, flyers, fitScore } = calculateIntercectedRoutesCost(
        [...intersectedRoutesRef.current?.values()],
        selectedTargets
      );
      setBlockData({ BUSINESS_C: business, HOME_COUNT: flyers, APT_COUNT: 0 });
      setTotalCost(cost);
      setFitScore(fitScore);
    }
  }, [isCalculatingRoutesIntercection, intersectedRoutesRef.current]);

  useEffect(() => {
    if (containerRef.current && mousePositionCoords) {
      setTooltipPosition(null);
      const { arrowPosition, newTooltipPosition } = getTooltipPosition({ containerRef, mousePositionCoords });
      setTooltipArrowPosition(arrowPosition);
      setTooltipPosition(newTooltipPosition);
    }
  }, [mousePositionCoords, currentContainerRefBounds]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const getAddressesCount = () => {
    if (isCalculatingRoutesIntercection) {
      return <Skeleton width={40} height={20} animation="wave" />;
    } else if (!isCalculatingRoutesIntercection && blockData) {
      return formatNumber(blockData.HOME_COUNT + blockData.APT_COUNT);
    }
  };
  const getTotalCost = () => {
    if (isCalculatingRoutesIntercection) {
      return <Skeleton width={40} height={20} animation="wave" />;
    } else if (!isCalculatingRoutesIntercection && typeof totalCost === "number") {
      return (
        <>
          <span>$</span> <span className={classes.cost}>{formatNumber(toFixed2(totalCost))}</span>
        </>
      );
    }
  };

  const getFitScore = () => {
    if (isCalculatingRoutesIntercection) {
      return <Skeleton width={40} height={20} animation="wave" />;
    } else if (!isCalculatingRoutesIntercection && typeof fitScore === "string") {
      return (
        <>
          <span className={classes.cost}>{fitScore}</span>
        </>
      );
    }
  };

  const getTotalRoutes = () => {
    if (isCalculatingRoutesIntercection) {
      return <Skeleton width={20} height={20} animation="wave" />;
    } else {
      return intersectedRoutesRef?.current?.size || 0;
    }
  };

  return (
    <div
      ref={containerRef}
      className={cn(classes.statisticsBlockContainer, "mob-hidden")}
      style={{
        top: `${tooltipPosition?.y || 0}px`,
        left: `${tooltipPosition?.x || 0}px`,
        display: !tooltipPosition ? "none" : "flex",
      }}
    >
      {tooltipArrowPosition === "bottom" && <ArrowDropUpIcon className={classes.topTooltipArrow} />}
      {tooltipArrowPosition === "top" && <ArrowDropDownIcon className={classes.bottomTooltipArrow} />}
      {tooltipArrowPosition === "right" && <ArrowRightIcon className={classes.rightTooltipArrow} />}
      {tooltipArrowPosition === "left" && <ArrowLeftIcon className={classes.leftTooltipArrow} />}
      {!!selectedTargets?.length && (
        <>
          <div className={classes.statisticsSection}>
            <span className={classes.statisticsLabel}>Fit score</span>
            <span className={classes.statisticsAmount}>
              <StarIcon /> {getFitScore()}
            </span>
          </div>
          <div className={classes.verticalSeparator} />
        </>
      )}
      <div className={classes.statisticsSection}>
        <span className={classes.statisticsLabel}>Routes</span>
        <span className={classes.statisticsAmount}>
          <RouteIcon /> {getTotalRoutes()}
        </span>
      </div>
      <div className={classes.verticalSeparator} />
      <div className={classes.statisticsSection}>
        <span className={classes.statisticsLabel}>Residences</span>
        <span className={classes.statisticsAmount}>
          <ResidencesIcon /> {getAddressesCount()}
        </span>
      </div>
      <div className={classes.verticalSeparator} />
      <div className={classes.statisticsSection}>
        <span className={classes.statisticsLabel}>Costs</span>
        <span className={classes.statisticsAmount}>{getTotalCost()}</span>
      </div>
    </div>
  );
};

import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import { useCountryStripe } from "./useStripe";

const CheckoutContainer = ({ fetchStripeCheckout, countryCode }) => {
  const options = { fetchClientSecret: fetchStripeCheckout };
  const stripePromise = useCountryStripe(countryCode);

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default CheckoutContainer;

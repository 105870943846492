export const styles = {
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0, 0.1)",
    zIndex: 9,
    position: "absolute" as const,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  choseLocationModal: {
    width: "548px",
    height: "434px",
    marginBottom: "110px",
    padding: "24px",
    borderRadius: "8px",
    background: "white",
    display: "flex",
    flexDirection: "column" as const,
  },
  title: {
    fontSize: "22px",
    fontWeight: 700,
    lineHeight: "22px",
  },
  subtitle: {
    margin: "18px 0 5px 0",
    fontSize: "13px",
    color: "#2E3860",
  },
  text: {
    fontSize: "14px",
    color: "#2E3860",
    marginTop: "3px",
  },
  mandatory: {
    color: "#D72B4F",
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    gap: "8px",
  },
  button: {
    position: "relative" as const,
    cursor: "pointer",
    fontWeight: 700,
    marginTop: "15px",
    border: "1px solid #E2E2FC",
    borderRadius: "4px",
    padding: "10px",
    width: "100%",
  },
  buttonSuggestion: {
    position: "relative" as const,
    cursor: "pointer",
    fontWeight: 700,
    marginTop: "15px",
    border: "1px solid #E2E2FC",
    borderRadius: "4px",
    padding: "10px",
    width: "100%",
    backgroundColor: "#EE4360",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  disabled: {
    cursor: "default",
    color: "#E2E2FC",
  },
  disabledSuggestion: {
    backgroundColor: "#C2C8D1",
    cursor: "default",
  },
  submittingIcon: {
    position: "absolute" as const,
    top: "11px",
    right: "12%",
    color: "black",
  },
};
